import { Injectable } from "@angular/core";

import * as fs from "file-saver";
// import * as XLSX from 'xlsx';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as moment from "moment";
import * as objLodash from "lodash";
import * as _ from "lodash";
import { extendMoment } from "moment-range";

import {
  Extracts,
  ExtractHeader,
} from "../reports/marketing-report/shared/extract.model";
import {
  DealerActivitiesList,
  Sources,
} from "../reports/marketing-report/shared/dealeractivities.model";
import { MarkettingSource } from "../reports/marketing-report/shared/markettingSource.model";
import { MarkettingList } from "../reports/marketing-report/shared/markettingList.model";
import { Prospect3List } from "./../reports/sale-report/shared/prospect3.model";
import { Source } from "./../reports/sale-report/shared/prospect2-header.model";
import { OverAllData } from "../reports/marketing-report/shared/overall.model";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.ws;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
const FORMULA = 6;
const MERGECELL = 3;

@Injectable()
export class ExcelService {
  async exportWarningSummary(
    json: any,
    fileName: string,
    dealers: string,
    dateRange: string
  ) {
    // console.log('json: ', json);
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("WARNING");
    const REPORT_NAME = "Warning – Summary";
    const tail = `${fileName}_${moment().format("x")}`;
    wb.creator = "Ford LMS";

    // insert report name
    let currentRow = 1;
    // add date group row
    // ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    // ws.getCell(`B${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    // ws.getCell(`D${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    currentRow++; // row #2
    // ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`B${currentRow}`).value = "DEALER";
    // ws.getCell(`B${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = dealers;
    // ws.getCell(`D${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    currentRow++; // row #3
    // ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    // ws.getCell(`B${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    ws.getCell(`B${currentRow}`).font = { bold: true };
    // const date: any[] = fileName.split('_');
    ws.getCell(`C${currentRow}`).value = dateRange;
    // ws.getCell(`D${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };

    const header = [
      "NO",
      "CREATED DATE",
      "DEALER CODE",
      "SC STAR ID",
      "SC NAME",
      "SC PHONE",
      "SM STAR ID",
      "SM NAME",
      "SM PHONE",
      "HAC NAME",
      "HAC PHONE",
      "SOURCE",
      "MEDIA",
      "CAR MODEL",
      "STATUS",
    ];

    ws.addRow(header).eachCell((cell, number) => {
      let color = "0070C0";
      if (number === 1) {
        cell.width = 30;
      } else if (number === 2) {
        cell.width = 80;
      }
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const data = json;
    for (let i = 0; i < data.length; i++) {
      const obj = data[i];
      const rowData = [
        i + 1,
        obj.createdDate,
        obj.dealerCode,
        obj.starId,
        obj.scName + " " + obj.lastName,
        obj.scPhone,
        obj.smStarId,
        obj.smName + " " + obj.smLastName,
        obj.smPhone,
        obj.hacName + " " + obj.hacLastName,
        obj.hacPhone,
        obj.sourceText,
        obj.mediaText,
        obj.carModel,
        obj.status,
      ];
      // data[i].pop();
      // console.log('data: ', rowData);
      ws.addRow(rowData).eachCell((cell, number) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    // Generate Excel File with given name
    this.autoSizeColumn(ws, 5);
    // ws.columns[0].width = 10;
    // ws.columns[1].width = 15;
    // ws.columns[4].alignment = {
    //   vertical: 'middle',
    //   horizontal: 'center', wrapText: true
    // };
    // ws.columns[5].width = 10;
    ws.columns[0].alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    wb.xlsx.writeBuffer().then((info: ArrayBuffer) => {
      const blob = new Blob([info], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, fileName + ".xlsx");
    });
  }

  async exportBookingDataManagementTable(
    json: any,
    fileName: string,
    saleName: string,
    paymentType: string,
    carModel: string,
    financeActivity: string,
    status: string,
    dateRange: any[]
  ) {
    // console.log('json: ', json);
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const REPORT_NAME = "Prospect Data Management Report";
    const ws = wb.addWorksheet("sheet1");
    const emptyRow = [];
    // loop throuth header
    for (let b = 0; b < 15; b++) {
      emptyRow.push("");
    }
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = `${moment(dateRange[0]).format(
      "DD MMM YYYY"
    )} - ${moment(dateRange[1]).format(
      "DD MMM YYYY"
    )} / Sale Name: ${saleName} / Payment Type: ${paymentType} / Model: ${carModel} / Finance Activity: ${financeActivity} / Status: ${status}`;

    ws.addRow(emptyRow);

    const header = [
      "No",
      "Current Status",
      "BPID",
      "Customer Name",
      "Mobile",
      "Car Model",
      "Car Name",
      "Booking Date",
      "Sale Name",
      "Start ID",
      "Booking ID",
      "Payment Type",
      "Finance Status",
      "Last Status",
      "Last Status Date",
      "Prospect Created Date",
      "Dealer Code",
    ];

    ws.addRow(header).eachCell((cell, number) => {
      let color = "0070C0";
      if (number === 1) {
        cell.width = 30;
      } else if (number === 2) {
        cell.width = 80;
      }
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const data = json;
    //console.log('data: ', json, ' lenght: ', data.length);
    let i = 0;
    for (const item of data) {
      //console.log('data[i] ', item.bpid);
      i++;
      const rowData = [
        i,
        item.statusName,
        item.bpid,
        item.customerName,
        item.mobile,
        item.carModelName,
        item.carName,
        item.bookingCreated,
        item.saleName,
        item.starId,
        item.bookingDocName,
        item.paymentType,
        item.financeActivity,
        item.statusText,
        item.lastActivityDate,
        item.prospectCreated,
        item.dealerCode,
      ];
      // const rowData = [(i + 1),''];
      // console.log('data:', rowData);
      ws.addRow(rowData).eachCell((cell, number) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    // Generate Excel File with given name
    this.autoSizeColumn(ws, 4);
    ws.columns[0].alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    wb.xlsx.writeBuffer().then((info: ArrayBuffer) => {
      const blob = new Blob([info], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, fileName + ".xlsx");
    });
  }
  async exportProspectDataManagementTable(
    json: any,
    fileName: string,
    saleName: string,
    prospectStatus: string,
    carModel: string,
    dateRange: any[]
  ) {
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const REPORT_NAME = "Prospect Data Management Report";
    const ws = wb.addWorksheet("prospect");
    const emptyRow = [];
    // loop throuth header
    for (let b = 0; b < 12; b++) {
      emptyRow.push("");
    }
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = `${moment(dateRange[0]).format(
      "DD MMM YYYY"
    )} - ${moment(dateRange[1]).format(
      "DD MMM YYYY"
    )} / Sale Name: ${saleName} / Status: ${prospectStatus} / Model: ${carModel}`;

    // add header
    ws.addRow(emptyRow);
    const header = [
      "No",
      "BPID",
      "Customer Name",
      "Car Model",
      "Car Name",
      "Sale Name",
      "Prospect Created Date",
      "Expected Date to Buy",
      "Type",
      "Last Activity",
      "Last Activity Date",
      "Prospect Status",
      "Transaction ID",
      "Dealer Code",
    ];

    ws.addRow(header).eachCell((cell, number) => {
      let color = "0070C0";
      if (number === 1) {
        cell.width = 30;
      } else if (number === 2) {
        cell.width = 80;
      }
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const data = json;
    //console.log('data: ', json, ' lenght: ', data.length);
    let i = 0;
    for (const item of data) {
      //console.log('data[i] ', item.bpid);
      i++;
      const rowData = [
        i,
        item.bpid,
        item.customerName,
        item.carModelName,
        item.carName,
        item.saleName,
        item.prospectCreated,
        item.willBuyAt,
        item.celsius,
        item.statusName,
        item.lastActivityDate,
        item.prospectStatus,
        item.enquiryId,
        item.dealerCode,
      ];
      // const rowData = [(i + 1),''];
      // console.log('data:', rowData);
      ws.addRow(rowData).eachCell((cell, number) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    // Generate Excel File with given name
    this.autoSizeColumn(ws, 4);
    ws.columns[0].alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    wb.xlsx.writeBuffer().then((info: ArrayBuffer) => {
      const blob = new Blob([info], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, fileName + ".xlsx");
    });
  }

  async exportLeadDataManagementTable(
    json: any,
    fileName: string,
    selectedCustomerType: string,
    saleName: string,
    status: string,
    dateRange: any[]
  ) {
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const REPORT_NAME = "Lead Data Management Report";
    const ws = wb.addWorksheet("lead");
    const emptyRow = [];
    // loop throuth header
    for (let b = 0; b < 11; b++) {
      emptyRow.push("");
    }
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = `${moment(dateRange[0]).format(
      "DD MMM YYYY"
    )} - ${moment(dateRange[1]).format(
      "DD MMM YYYY"
    )} / Customer type: ${selectedCustomerType} / Sale Person: ${saleName} / Status: ${status}`;

    // add header
    ws.addRow(emptyRow);
    const header = [
      "No",
      "Customer Name",
      "Mobile",
      "Car Model",
      "Car Name",
      "Source",
      "Lead Created Date",
      "Last Sale Consultant",
      "Assigned To",
      "Lead Status",
      "Dealer Code",
    ];

    ws.addRow(header).eachCell((cell, number) => {
      let color = "0070C0";
      if (number === 1) {
        cell.width = 30;
      } else if (number === 2) {
        cell.width = 80;
      }
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const data = json;
    //console.log('data: ', json, ' lenght: ', data.length);
    let i = 0;
    for (const item of data) {
      //console.log('data[i] ', item.bpid);
      i++;
      const rowData = [
        i,
        item.customerName,
        item.mobile,
        item.carModel,
        item.carName,
        item.source,
        item.createdDate,
        item.latestSc,
        item.assigned,
        item.leadStatus,
        item.dealerCode,
      ];
      ws.addRow(rowData).eachCell((cell, number) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    // Generate Excel File with given name
    this.autoSizeColumn(ws, 4);
    ws.columns[0].alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    wb.xlsx.writeBuffer().then((info: ArrayBuffer) => {
      const blob = new Blob([info], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, fileName + ".xlsx");
    });
  }

  async exportupdateVinDataManagementTable(
    json: any,
    fileName: string,
    selectedCustomerType: string,
    saleName: string,
    status: string,
    dateRange: any[]
  ) {
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const REPORT_NAME = "Lead Data Management Report";
    const ws = wb.addWorksheet("lead");
    const emptyRow = [];
    // loop throuth header
    for (let b = 0; b < 11; b++) {
      emptyRow.push("");
    }
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = `${moment(dateRange[0]).format(
      "DD MMM YYYY"
    )} - ${moment(dateRange[1]).format(
      "DD MMM YYYY"
    )} / Customer type: ${selectedCustomerType} / Sale Person: ${saleName} / Status: ${status}`;

    // add header
    ws.addRow(emptyRow);
    const header = [
      "No",
      "Sale Name",
      "Star ID",
      "Customer Name",
      "Delivered At",
      "Vin",
    ];

    ws.addRow(header).eachCell((cell, number) => {
      let color = "0070C0";
      if (number === 1) {
        cell.width = 30;
      } else if (number === 2) {
        cell.width = 80;
      }
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const data = json;
    //console.log('data: ', json, ' lenght: ', data.length);
    let i = 0;
    for (const item of data) {
      //console.log('data[i] ', item.bpid);
      i++;
      const rowData = [
        i,
        item.staffName,
        item.starId,
        item.customerName,
        item.deliveredAt,
        item.vin,
      ];
      ws.addRow(rowData).eachCell((cell, number) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    // Generate Excel File with given name
    this.autoSizeColumn(ws, 4);
    ws.columns[0].alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    wb.xlsx.writeBuffer().then((info: ArrayBuffer) => {
      const blob = new Blob([info], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, fileName + ".xlsx");
    });
  }

  async exportSaleDirectAssignedTable(
    json: any,
    fileName: string,
    date: any
  ) {
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const REPORT_NAME = "Sale Direct Assigned Report";
    const ws = wb.addWorksheet("lead");
    const emptyRow = [];
    // loop throuth header
    for (let b = 0; b < 11; b++) {
      emptyRow.push("");
    }
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = `${moment(date).format(
      "DD MMM YYYY"
    )}`;

    // add header
    ws.addRow(emptyRow);
    const header = [
      "No",
      "Dealer Code",
      "Dealer Name",
      "First Name",
      "Last Name",
      "Star ID",
      "Phone",
    ];

    ws.addRow(header).eachCell((cell, number) => {
      let color = "0070C0";
      if (number === 1) {
        cell.width = 50;
      } else if (number === 2) {
        cell.width = 80;
      }
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const data = json;
    //console.log('data: ', json, ' lenght: ', data.length);
    let i = 0;
    for (const item of data) {
      //console.log('data[i] ', item.bpid);
      i++;
      const rowData = [
        i,
        item.dealer_code,
        item.dealer_description,
        item.firstName,
        item.lastName,
        item.starId,
        item.phone,
      ];
      ws.addRow(rowData).eachCell((cell, number) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    // Generate Excel File with given name
    this.autoSizeColumn(ws, 4);
    ws.columns[0].alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    wb.xlsx.writeBuffer().then((info: ArrayBuffer) => {
      const blob = new Blob([info], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, fileName + ".xlsx");
    });
  }

  async exportCurrentHighAttention(json: any, fileName: string) {
    // console.log('json: ', json);
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("HAC");
    const header = ["NO", "Title", "First Name", "Last Name"];

    ws.addRow(header).eachCell((cell, number) => {
      let color = "0070C0";
      if (number === 1) {
        cell.width = 30;
      } else if (number === 2) {
        cell.width = 80;
      }
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const data = json;
    //console.log('data: ', json, ' lenght: ', data.length);
    for (let i = 0; i < data.length; i++) {
      const rowData = [
        i + 1,
        data[i].Title || "",
        data[i].FirstName,
        data[i].LastName,
      ];
      // data[i].pop();
      ws.addRow(rowData).eachCell((cell, number) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    // Generate Excel File with given name
    this.autoSizeColumn(ws, 2);
    // ws.columns[0].width = 10;
    // ws.columns[1].width = 15;
    // ws.columns[4].alignment = {
    //   vertical: 'middle',
    //   horizontal: 'center', wrapText: true
    // };
    // ws.columns[5].width = 10;
    ws.columns[0].alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    wb.xlsx.writeBuffer().then((info: ArrayBuffer) => {
      const blob = new Blob([info], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, fileName + ".xlsx");
    });
  }

  constructor() { }

  public exportAsExcelFileUserLog(
    json: any,
    year,
    month,
    excelFileName: string,
    all = false,
    result = []
  ): void {
    // console.log('json: ', json);
    if (all === false) {
      const wb = new Excel.Workbook();
      const ws = wb.addWorksheet("User Log Data");
      const header = [
        "Dealer Code",
        "STAR ID",
        "User",
        "Position",
        "Total Number of user Login (Day)",
        "Waive",
      ];
      const totalDays = moment(year + "-" + month, "YYYY-MM").daysInMonth();
      const mName = moment(year + "-" + month).format("MMM");
      for (let i = 1; i <= totalDays; i++) {
        header.push(i + "-" + mName);
      }

      ws.addRow(header).eachCell((cell, number) => {
        let color = "FFFF00";
        if (number === 1) {
          cell.width = 65;
        } else if (number === 5) {
          cell.width = 90;
        }
        if (number <= 6) {
          color = "335693";
          cell.font = { bold: true, color: { argb: "FFFFFF" } };
        } else {
          cell.font = { bold: true };
        }
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: color },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });

      const data = json;
      let isPass = "Pass";
      let failNumber = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i][data[i].length - 1] === "F") {
          isPass = "Fail";
        }
        data[i].pop();
        ws.addRow(data[i]).eachCell((cell, number) => {
          if (cell.value === "Not Pass") {
            failNumber++;
          }
          if (cell.value === "Not Pass" || cell.value === "Not Active") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF6503" },
            };
          }
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      }

      // Generate Excel File with given name
      // this.autoSizeColumn(ws, 2);
      ws.columns[0].width = 10;
      ws.columns[4].width = 15;
      ws.columns[4].alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      ws.columns[5].width = 10;
      ws.columns[5].alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
        const blob = new Blob([dataCar], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
        });
        fs.saveAs(blob, excelFileName + ".xlsx");
      });
    }

    if (all === true) {
      const data = json;
      let count = 0;
      const wb = new Excel.Workbook();
      let ws = wb.addWorksheet("User Log Report");
      let header = [
        "Dealer Code",
        "Dealer Name",
        "Total Number of SM",
        "Total Number of SM Login 22 Days",
        "Result",
      ];
      ws.addRow(header).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "335693" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        cell.font = { bold: true, color: { argb: "FFFFFF" } };
      });
      // this.autoSizeColumn(ws, 1);
      ws.columns[0].width = 10;
      ws.columns[1].width = 40;
      ws.columns[2].width = 15;
      ws.columns[2].alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      ws.columns[3].width = 15;
      ws.columns[3].alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      ws.columns[4].width = 10;
      ws.columns[4].alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      for (let i = 0; i < result.length; i++) {
        ws.addRow(result[i]).eachCell((cell, number) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      }
      const keys = Object.keys(data);
      for (const i of keys) {
        // i is code
        ws = wb.addWorksheet(i);
        header = [
          "Dealer Code",
          "STAR ID",
          "User",
          "Position",
          "Total Number of user Login (Day)",
          "Waive",
        ];
        const totalDays = moment(year + "-" + month, "YYYY-MM").daysInMonth();
        const mName = moment(year + "-" + month).format("MMM");
        for (let day = 1; day <= totalDays; day++) {
          header.push(day + "-" + mName);
        }
        ws.addRow(header).eachCell((cell, number) => {
          let color = "FFFF00";
          if (number === 1) {
            cell.width = 65;
          } else if (number === 5) {
            cell.width = 90;
          }
          if (number <= 6) {
            color = "335693";
            cell.font = { bold: true, color: { argb: "FFFFFF" } };
          } else {
            cell.font = { bold: true };
          }
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: color },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
        let isPass = "Pass";
        let failNumber = 0;
        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j][data[i][j].length - 1] === "F") {
            isPass = "Fail";
          }
          data[i][j].pop();
          ws.addRow(data[i][j]).eachCell((cell, number) => {
            if (cell.value === "Not Pass") {
              failNumber++;
            }
            if (cell.value === "Not Pass" || cell.value === "Not Active") {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF6503" },
              };
            }
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        }

        if (count === Object.keys(data).length - 1) {
          // Generate Excel File with given name
          wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
            const blob = new Blob([dataCar], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
            });
            fs.saveAs(blob, excelFileName + ".xlsx");
          });
        } else {
          // this.autoSizeColumn(ws, 2);
          ws.columns[0].width = 10;
          ws.columns[4].width = 14.17;
          ws.columns[4].alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
          ws.columns[5].width = 10;
          ws.columns[5].alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        }
        count++;
      }
    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // Excel Title, Header, Data
    const title = "Car Sell Report";
    const header = ["Year", "Month", "Make", "Model", "Quantity", "Pct"];
    const headerModelName = [
      "Region",
      "STD 2.2L XL HP 4X2 LR",
      "DBL 3.2L XLT 4x4 AT",
      "STD 2.2L XL HP 4X2 LR",
      "DBL 3.2L XLT 4x4 AT",
      "รวมRanger",
      "3.2LTitanium 4x4 AT",
      "2.2LTitanium + 4x2 AT-NAVI",
      "2.0L Turbo 4x2 Titanium+",
      "2.0L Bi-Turbo 4x4 Titanium+",
      "2.0L Turbo 4x2 Titanium",
      "2.0L Turbo 4x2 Trend",
      "2.0L Turbo 4x2 Titanium+",
      "2.0L Bi-Turbo 4x4 Titanium+",
      "2.0L Turbo 4x2 Titanium",
      "2.0L Turbo 4x2 Trend",
      "2.0L Turbo 4x2 Titanium+",
      "2.0L Bi-Turbo 4x4 Titanium+",
      "2.0L Turbo 4x2 Titanium",
      "2.0L Turbo 4x2 Trend",
      "รวมEverest",
      "2.3L EcoBoost Coupe",
      "5.0L V8 GT Coupe",
      "2.3L EcoBoost Coupe",
      "5.0L V8 GT Coupe",
      "รวมMustang",
      "รวม",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    const HeaderModel = [
      "",
      "iFAB9A5",
      "iFBT9B4",
      "IJAB9A5",
      "IJBT9B4",
      "-",
      "zFAJ9GD",
      "ZJAE9BC",
      "zNAE9KE",
      "zNAE9MF",
      "zNAJ9KE",
      "zNAV9KE",
      "zRAE9KE",
      "zRAE9MF",
      "zRAJ9KE",
      "zRAV9KE",
      "zTAE9KE",
      "zTAE9MF",
      "zTAJ9KE",
      "zTAV9KE",
      "-",
      "7NJ9EEA",
      "7NJ9MDA",
      "SJMB9BW",
      "SJMX9AW",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];

    const dataTeamA = [
      "BKK&Metro",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ];
    const dataTeamB = [
      "Central",
      "0",
      "0",
      "4",
      "0",
      "4",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2",
      "0",
      "0",
      "0",
      "8",
      "0",
      "0",
      "3",
      "2",
      "5",
      "0",
      "0",
      "0",
      "0",
      "0",
      "13",
    ];
    const dataNorthEast = [
      "North East",
      "0",
      "0",
      "0",
      "0",
      "4",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2",
      "0",
      "0",
      "0",
      "8",
      "0",
      "0",
      "3",
      "2",
      "5",
      "0",
      "0",
      "0",
      "0",
      "0",
      "13",
    ];
    const dataSouth = [
      "South",
      "0",
      "0",
      "0",
      "0",
      "4",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2",
      "0",
      "0",
      "0",
      "8",
      "0",
      "0",
      "3",
      "2",
      "5",
      "0",
      "0",
      "0",
      "0",
      "0",
      "13",
    ];
    const dataTotal = [
      "Total",
      "0",
      "0",
      "0",
      "0",
      "4",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2",
      "0",
      "0",
      "0",
      "8",
      "0",
      "0",
      "3",
      "2",
      "5",
      "0",
      "0",
      "0",
      "0",
      "0",
      "13",
    ];
    const dataTeamAEveryMonth = [
      "BKK&Metro",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ];
    const dataTeamBEveryMonth = [
      "Central",
      "0",
      "0",
      "0",
      "0",
      "3",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "5",
      "0",
      "0",
      "2",
      "2",
      "4",
      "0",
      "0",
      "0",
      "0",
      "0",
      "9",
    ];
    const dataNorthEastEveryMonth = [
      "North East",
      "0",
      "0",
      "0",
      "0",
      "4",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2",
      "0",
      "0",
      "0",
      "8",
      "0",
      "0",
      "3",
      "2",
      "5",
      "0",
      "0",
      "0",
      "0",
      "0",
      "13",
    ];
    const dataSouthEveryMonth = [
      "South",
      "0",
      "0",
      "0",
      "0",
      "4",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "2",
      "0",
      "0",
      "0",
      "8",
      "0",
      "0",
      "3",
      "2",
      "5",
      "0",
      "0",
      "0",
      "0",
      "0",
      "13",
    ];
    const dataTotalEveryMonth = [
      "Total",
      "0",
      "0",
      "0",
      "0",
      "3",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "5",
      "0",
      "0",
      "2",
      "2",
      "4",
      "0",
      "0",
      "0",
      "0",
      "0",
      "9",
    ];
    const dataTeamANewBooking = [
      "BKK&Metro",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ];
    const dataTeamBNewBooking = [
      "Central",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3",
    ];
    const dataNorthEastNewBooking = [
      "North East",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3",
    ];
    const dataSouthNewBooking = [
      "South",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3",
    ];
    const dataTotalNewBooking = [
      "Total",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "1",
      "0",
      "1",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "3",
    ];
    const dataTeamADelivery = [
      "BKK&Metro",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ];
    const dataTeamBDelivery = [
      "Central",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ];
    const dataTotalDelivery = [
      "Total",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ];
    const data = [
      [2007, 1, "Volkswagen ", "Volkswagen Passat", 1267, 10],
      [2007, 1, "Toyota ", "Toyota Rav4", 819, 6.5],
      [2007, 1, "Toyota ", "Toyota Avensis", 787, 6.2],
      [2007, 1, "Volkswagen ", "Volkswagen Golf", 720, 5.7],
      [2007, 1, "Toyota ", "Toyota Corolla", 691, 5.4],
      [2007, 1, "Peugeot ", "Peugeot 307", 481, 3.8],
      [2008, 1, "Toyota ", "Toyota Prius", 217, 2.2],
      [2008, 1, "Skoda ", "Skoda Octavia", 216, 2.2],
      [2008, 1, "Peugeot ", "Peugeot 308", 135, 1.4],
      [2008, 2, "Ford ", "Ford Mondeo", 624, 5.9],
      [2008, 2, "Volkswagen ", "Volkswagen Passat", 551, 5.2],
      [2008, 2, "Volkswagen ", "Volkswagen Golf", 488, 4.6],
      [2008, 2, "Volvo ", "Volvo V70", 392, 3.7],
      [2008, 2, "Toyota ", "Toyota Auris", 342, 3.2],
      [2008, 2, "Volkswagen ", "Volkswagen Tiguan", 340, 3.2],
      [2008, 2, "Toyota ", "Toyota Avensis", 315, 3],
      [2008, 2, "Nissan ", "Nissan Qashqai", 272, 2.6],
      [2008, 2, "Nissan ", "Nissan X-Trail", 271, 2.6],
      [2008, 2, "Mitsubishi ", "Mitsubishi Outlander", 257, 2.4],
      [2008, 2, "Toyota ", "Toyota Rav4", 250, 2.4],
      [2008, 2, "Ford ", "Ford Focus", 235, 2.2],
      [2008, 2, "Skoda ", "Skoda Octavia", 225, 2.1],
      [2008, 2, "Toyota ", "Toyota Yaris", 222, 2.1],
      [2008, 2, "Honda ", "Honda CR-V", 219, 2.1],
      [2008, 2, "Audi ", "Audi A4", 200, 1.9],
      [2008, 2, "BMW ", "BMW 3-serie", 184, 1.7],
      [2008, 2, "Toyota ", "Toyota Prius", 165, 1.6],
      [2008, 2, "Peugeot ", "Peugeot 207", 144, 1.4],
    ];

    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("Car Data");
    // Add Row and formatting
    // ws.addRow([title]);

    // ws.addRow([]);

    ws.addRow(["รายงานรอบเดือน November 2019"]);
    ws.addRow([]);
    const setbg1 = ws.addRow([
      "ยอดจองคงค้างยกมาทั้งหมดไม่รวมเดือนปัจจุบัน (Pending Booking Excluding Current Month)",
    ]);
    ws.mergeCells("A3:S3");
    setbg1.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E7C978" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    const setbg2 = ws.addRow(headerModelName);
    setbg2.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "87ECE6" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    const setbg3 = ws.addRow(HeaderModel);
    setbg3.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B3FC9E" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    /** Set team background */
    ws.mergeCells("A4:A5");
    const TeambgColor = setbg2.getCell(1);
    TeambgColor.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "E19E3A" },
    };

    /**Set รวมRanger */
    ws.mergeCells("AB4:AB5");
    const MixRangerbgColor = setbg2.getCell(28);
    MixRangerbgColor.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };

    /**Set รวมEverest */
    ws.mergeCells("AG4:AG5");
    const MixEverestbgColor = setbg2.getCell(33);
    MixEverestbgColor.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };
    /**Set รวมMustang */
    ws.mergeCells("AL4:AL5");
    const MixMustangbgColor = setbg2.getCell(38);
    MixMustangbgColor.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };
    /**Set รวม */
    ws.mergeCells("AM4:AM5");
    const MixTotalbgColor = setbg2.getCell(39);
    MixTotalbgColor.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FCE8B1" },
    };

    /**Data display */
    ws.addRow(dataTeamA).eachCell((cell, number) => {
      if (number === 28 || number === 33 || number === 38) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9AF69C" },
        };
      } else if (number === 39) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FCE8B1" },
        };
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(dataTeamB).eachCell((cell, number) => {
      if (number === 28 || number === 33 || number === 38) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9AF69C" },
        };
      } else if (number === 39) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FCE8B1" },
        };
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(dataTotal).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E19E3A" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    /**ยอด 1 ทุกเดือน */
    ws.addRow([]);
    // tslint:disable-next-line:max-line-length
    ws.addRow([
      "ยอดจองคงค้างที่มีแนวโน้มจะปล่อยได้ในเดือนนี้ (ยอดค้างจองยกยอดมา ณ วันที่ 1 ของทุกเดือน) - Pending Booking",
    ]).eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E7C978" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    ws.mergeCells("A10:S10");

    const HeaderModelEveryMonth = ws.addRow(headerModelName);
    HeaderModelEveryMonth.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "87ECE6" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(HeaderModel).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B3FC9E" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    /** Set team background */
    ws.mergeCells("A11:A12");
    const TeamEveryMonthbgColor = HeaderModelEveryMonth.getCell(1);
    TeamEveryMonthbgColor.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "E19E3A" },
    };

    /**Set รวมRanger */
    ws.mergeCells("AB11:AB12");
    HeaderModelEveryMonth.getCell(28).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };

    /**Set รวมEverest */
    ws.mergeCells("AG11:AG12");
    HeaderModelEveryMonth.getCell(33).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };
    /**Set รวมMustang */
    ws.mergeCells("AL11:AL12");
    HeaderModelEveryMonth.getCell(38).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };
    /**Set รวม */
    ws.mergeCells("AM11:AM12");
    HeaderModelEveryMonth.getCell(39).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FCE8B1" },
    };

    /**Data every month */
    ws.addRow(dataTeamAEveryMonth).eachCell((cell, number) => {
      if (number === 28 || number === 33 || number === 38) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9AF69C" },
        };
      } else if (number === 39) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FCE8B1" },
        };
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(dataTeamBEveryMonth).eachCell((cell, number) => {
      if (number === 28 || number === 33 || number === 38) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9AF69C" },
        };
      } else if (number === 39) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FCE8B1" },
        };
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(dataTotalEveryMonth).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E19E3A" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    /**ยอด New Booking */
    ws.addRow([]);
    // tslint:disable-next-line:max-line-length
    ws.addRow([
      "ยอดจองเดือนปัจจุบัน (ยอดจองเดือนนี้ตั้งเต่วันที่ 1) - New Booking",
    ]).eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E7C978" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    ws.mergeCells("A17:S17");

    const HeaderModelNewBooking = ws.addRow(headerModelName);
    HeaderModelNewBooking.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "87ECE6" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(HeaderModel).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B3FC9E" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    /** Set team background */
    ws.mergeCells("A18:A19");
    const TeamNewBookingbgColor = HeaderModelNewBooking.getCell(1);
    TeamNewBookingbgColor.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "E19E3A" },
    };

    /**Set รวมRanger */
    ws.mergeCells("AB18:AB19");
    HeaderModelNewBooking.getCell(28).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };

    /**Set รวมEverest */
    ws.mergeCells("AG18:AG19");
    HeaderModelNewBooking.getCell(33).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };
    /**Set รวมMustang */
    ws.mergeCells("AL18:AL19");
    HeaderModelNewBooking.getCell(38).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };
    /**Set รวม */
    ws.mergeCells("AM18:AM19");
    HeaderModelNewBooking.getCell(39).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FCE8B1" },
    };

    /**Data every month */
    ws.addRow(dataTeamANewBooking).eachCell((cell, number) => {
      if (number === 28 || number === 33 || number === 38) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9AF69C" },
        };
      } else if (number === 39) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FCE8B1" },
        };
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(dataTeamBNewBooking).eachCell((cell, number) => {
      if (number === 28 || number === 33 || number === 38) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9AF69C" },
        };
      } else if (number === 39) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FCE8B1" },
        };
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(dataTotalNewBooking).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E19E3A" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    /**ยอด New Booking */
    ws.addRow([]);
    // tslint:disable-next-line:max-line-length
    ws.addRow([
      "ยอดปล่อยรถในเดือนปัจจุบัน (ยอดปล่อยเดือนนี้ตั้งเต่วันที่ 1) - Delivery",
    ]).eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E7C978" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    ws.mergeCells("A24:S24");

    const HeaderModelDelivery = ws.addRow(headerModelName);
    HeaderModelDelivery.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "87ECE6" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(HeaderModel).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B3FC9E" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    /** Set team background */
    ws.mergeCells("A25:A26");
    const TeamDeliverybgColor = HeaderModelDelivery.getCell(1);
    TeamDeliverybgColor.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "E19E3A" },
    };

    /**Set รวมRanger */
    ws.mergeCells("AB25:AB26");
    HeaderModelDelivery.getCell(28).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };

    /**Set รวมEverest */
    ws.mergeCells("AG25:AG26");
    HeaderModelDelivery.getCell(33).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };
    /**Set รวมMustang */
    ws.mergeCells("AL25:AL26");
    HeaderModelDelivery.getCell(38).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "9AF69C" },
    };
    /**Set รวม */
    ws.mergeCells("AM25:AM26");
    HeaderModelDelivery.getCell(39).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FCE8B1" },
    };

    /**Data every month */
    ws.addRow(dataTeamADelivery).eachCell((cell, number) => {
      if (number === 28 || number === 33 || number === 38) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9AF69C" },
        };
      } else if (number === 39) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FCE8B1" },
        };
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(dataTeamBDelivery).eachCell((cell, number) => {
      if (number === 28 || number === 33 || number === 38) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9AF69C" },
        };
      } else if (number === 39) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FCE8B1" },
        };
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ws.addRow(dataTotalDelivery).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E19E3A" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Generate Excel File with given name
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, "CarData.xlsx");
    });
  }

  private calculateGrandTotal(data: any) {
    // Calculate Grand Total
    /* control flow *
    - loop grand total ไปทีละ model -> serie -> car name
    - ในแต่ละรอบของการหมุน ให้ loop customer source ไปทีละ source -> model -> serie -> car name
    - ถ้า car name ตรงกันก็ + carValue ใน grand total ตาม car name นั้น ๆ
    */
    const doc = data;
    // const doc: Prospect2list[] = data;
    // console.log('doc: ', doc);
    // doc คือจำนวน dealer ทั้งหมด
    doc.forEach((a) => {
      // หาค่า index ของ grand total
      const aI = a.sources.length - 1;
      for (
        let modelGrandTotal = 0;
        modelGrandTotal < a.sources[aI].model.length;
        modelGrandTotal++
      ) {
        const modelNameGrandTotal =
          a.sources[aI].model[modelGrandTotal].modelName;
        for (
          let serieGrandTotal = 0;
          serieGrandTotal < a.sources[aI].model[modelGrandTotal].serie.length;
          serieGrandTotal++
        ) {
          const serieNameGrandTotal =
            a.sources[aI].model[modelGrandTotal].serie[serieGrandTotal]
              .serieName;
          for (
            let carNamesGrandTotal = 0;
            carNamesGrandTotal <
            a.sources[aI].model[modelGrandTotal].serie[serieGrandTotal].carNames
              .length;
            carNamesGrandTotal++
          ) {
            const carNameGrandTotal =
              a.sources[aI].model[modelGrandTotal].serie[serieGrandTotal]
                .carNames[carNamesGrandTotal].carName;
            // วนหาจำนวนรถจากทุก source ยกเว้นที่มาจาก grand total
            for (let aSource = 0; aSource < aI; aSource++) {
              const aIdx = a.sources[aSource].model.findIndex((p) => {
                return p.modelName === modelNameGrandTotal;
              });
              if (aIdx !== -1) {
                const bIdx = a.sources[aSource].model[aIdx].serie.findIndex(
                  (p) => {
                    return p.serieName === serieNameGrandTotal;
                  }
                );
                if (bIdx !== -1) {
                  // let isNotSpecificCar = false;
                  const cIdx = a.sources[aSource].model[aIdx].serie[
                    bIdx
                  ].carNames.findIndex((p) => {
                    return p.carName === carNameGrandTotal;
                  });
                  // 2) copy `not specific xxx` code to prospect daily, booking summary, booking daily data.
                  if (cIdx !== -1) {
                    const value =
                      a.sources[aSource].model[aIdx].serie[bIdx].carNames[cIdx]
                        .carValue;
                    if (
                      modelNameGrandTotal == "not specific model" &&
                      serieNameGrandTotal == "not specific serie" &&
                      carNameGrandTotal == "not specific car"
                    ) {
                      // แยก a.sources ออกเนื่องจากเป็นเคสที่
                      // ข้อมูลที่ถูกส่งมาจาก SAP จะไม่มี source | model | serie | car name
                      // จึงจำเป็นต้องแทรก not specific xxx เข้ามา
                      a.sources[aI].model[modelGrandTotal].serie[
                        serieGrandTotal
                      ].carNames[carNamesGrandTotal].carValue += value;
                    } else {
                      a.sources[aI].model[aIdx].serie[bIdx].carNames[
                        cIdx
                      ].carValue += value;
                    }
                  }
                }
              }
            }
          }
        }
      }
    });
    return doc;
  }

  public exportProspect2ToXlsx(
    headers: Source[],
    data: any,
    fileName: string,
    dealerName: string
  ) {
    // console.log(headers);
    // window.open(URL.createObjectURL(
    //   new Blob([JSON.stringify(data)], {
    //     type: 'application/binary'
    //   }
    //   )
    // ));

    // console.log('cal grand total...');
    this.calculateGrandTotal(data);
    // console.log('data: ', [...data]);
    const REPORT_NAME = "Prospect – Summary";
    const tail = `${fileName}_${moment().format("x")}`;
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const ws = wb.addWorksheet(REPORT_NAME);
    // insert report name
    let currentRow = 1;
    // add date group row
    ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    // ws.getCell(`B${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    ws.getCell(`B${currentRow}`).font = { bold: true };
    // ws.mergeCells(`C${currentRow}:AH${currentRow}`);
    ws.getCell(`D${currentRow}`).value = REPORT_NAME;
    // ws.getCell(`D${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    currentRow++; // row #2
    ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`B${currentRow}`).value = "DEALER";
    // ws.getCell(`B${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    ws.getCell(`B${currentRow}`).font = { bold: true };
    // ws.mergeCells(`C${currentRow}:AH${currentRow}`);
    ws.getCell(`D${currentRow}`).value = dealerName;
    // ws.getCell(`D${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    currentRow++; // row #3
    ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    // ws.getCell(`B${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    ws.getCell(`B${currentRow}`).font = { bold: true };
    const date: any[] = fileName.split("_");
    // ws.mergeCells(`C${currentRow}:AH${currentRow}`);
    ws.getCell(`D${currentRow}`).value = `${moment(date[0]).format(
      "DD MMM YYYY"
    )} - ${moment(date[1]).format("DD MMM YYYY")}`;
    // ws.getCell(`D${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };

    // currentRow += 2;  // row #5
    // add header
    const cellA1toD1 = 4;
    const colGrandTotal = 1;
    // Customer Source Variable
    let startCustomerSourceColName = "";
    let startCustomerSourceCellMerge = 0;
    let stopCustomerSourceCellMerge = 0;
    // Series Variable
    let startSerieCellMergeName = "";
    let startSerieCellMerge = cellA1toD1;
    let stopSerieCellMerge = cellA1toD1;
    // Cars variable
    let startCarsCellMerge = cellA1toD1;
    let loop = 0;
    let startGrandtotal = 0;
    for (let i = 0; i < headers.length; i++) {
      // add cellmerge customer source.
      loop += 1;
      startCustomerSourceCellMerge =
        loop === 1 ? cellA1toD1 + 1 : stopCustomerSourceCellMerge + 1;
      startCustomerSourceColName = this.getColumnByNumber(
        startCustomerSourceCellMerge
      );
      stopCustomerSourceCellMerge =
        headers[i].cellMerge * loop + cellA1toD1 + colGrandTotal + i;
      // 3) set up cell merge to 'not specific xxx'.
      if (headers[i].sourceName == "not specific source") {
        stopCustomerSourceCellMerge = startCustomerSourceCellMerge;
        // เก็บตำแหน่งของ not specific source ไว้เพื่อเป็นเซลอ้างอิงของ grand total
        // create variable for grantotal
        startGrandtotal = stopCustomerSourceCellMerge;
      } else if (headers[i].sourceName == "Grand Total") {
        stopCustomerSourceCellMerge =
          headers[i].cellMerge + startGrandtotal + cellA1toD1;
      }
      const col = this.getColumnByNumber(stopCustomerSourceCellMerge);
      const colrange = `${startCustomerSourceColName}5:${col}5`;
      // console.log('colrange to merge: ', colrange);
      ws.mergeCells(colrange);
      ws.getCell(`${col}5`).value = headers[i].sourceName;
      ws.getCell(`${col}5`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${col}5`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: loop % 2 !== 0 ? "92D050" : "FFF2CC" },
      };
      ws.getCell(`${col}5`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.getCell(`${col}5`).font = { bold: true };
      // add serie to header
      ws.getCell("D6").value = "Series";
      ws.getCell("D6").alignment = { vertical: "middle", horizontal: "center" };
      ws.getCell("D6").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00B0F0" },
      };
      ws.getCell("D6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.getCell("B7").value = "Zone";
      ws.getCell("B7").alignment = { vertical: "middle", horizontal: "center" };
      ws.getCell("B7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.getCell("C7").value = "Dlr Code";
      ws.getCell("C7").alignment = { vertical: "middle", horizontal: "center" };
      ws.getCell("C7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.getCell("D7").value = "Dlr Name";
      ws.getCell("D7").alignment = { vertical: "middle", horizontal: "center" };
      ws.getCell("D7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      // Start model loop
      for (const mo of headers[i].model) {
        const modelName = mo.modelName;
        let serieName = "";
        // Start series loop
        for (const se of mo.serie) {
          serieName = se.serieName;
          // console.log(`headers: ${headers[i].sourceName} model: ${modelName} serieName: ${serieName}`);
          startSerieCellMerge =
            startSerieCellMerge === cellA1toD1
              ? cellA1toD1 + 1
              : stopSerieCellMerge + 1;
          startSerieCellMergeName = this.getColumnByNumber(startSerieCellMerge);
          // console.log(`cellSerieMerge af + ${se.cellMerge} + 4 : ${cellSerieMerge}`);
          stopSerieCellMerge += se.cellMerge;
          const stopSerieCellMergeName =
            this.getColumnByNumber(stopSerieCellMerge);
          const colSerieRange = `${startSerieCellMergeName}6:${stopSerieCellMergeName}6`;
          // console.log(`merge serie range: ${colSerieRange}`);
          ws.mergeCells(colSerieRange);
          ws.getCell(`${stopSerieCellMergeName}6`).value = serieName;
          ws.getCell(`${stopSerieCellMergeName}6`).alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          ws.getCell(`${stopSerieCellMergeName}6`).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
          // Start car name loop
          let ind = 0;
          for (const ca of se.carNames) {
            startCarsCellMerge += 1;
            const stopCarsCellMergeName =
              this.getColumnByNumber(startCarsCellMerge);
            ws.getCell(`${stopCarsCellMergeName}7`).value = ca.carName;
            if (ind === se.carNames.length - 1 && ind > 0) {
              ws.getCell(`${stopCarsCellMergeName}7`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFF2CC" },
              }; // สีเหลือง
            }
            ws.getCell(`${stopCarsCellMergeName}7`).alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
            ws.getCell(`${stopCarsCellMergeName}7`).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
              color: { argb: "000000" },
            };

            ind += 1;
          }
        }
        startCarsCellMerge += 1;
        // add total each modelName
        const colTotalModelName = this.getColumnByNumber(
          stopSerieCellMerge + 1
        );
        const colTotalModelRange = `${colTotalModelName}6:${colTotalModelName}7`;
        ws.mergeCells(colTotalModelRange);
        ws.getCell(`${colTotalModelName}6`).value = `Total\r\n${modelName}`;
        ws.getCell(`${colTotalModelName}6`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        ws.getCell(`${colTotalModelName}6`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFF2CC" },
        }; // สีเหลือง
        ws.getCell(`${colTotalModelName}6`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        stopSerieCellMerge += 1;
      }
      // Grand Total
      const colGrandTotalModelName = this.getColumnByNumber(
        stopSerieCellMerge + 1
      );
      const colGrandTotalModelRange = `${colGrandTotalModelName}6:${colGrandTotalModelName}7`;
      ws.mergeCells(colGrandTotalModelRange);
      ws.getCell(`${colGrandTotalModelName}6`).value = `Grand\r\nTotal`;
      ws.getCell(`${colGrandTotalModelName}6`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${colGrandTotalModelName}6`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC000" },
      }; // สีเหลืองเข้ม
      ws.getCell(`${colGrandTotalModelName}6`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      stopSerieCellMerge += 1;
      startCarsCellMerge += 1;
    }

    data.sort(function (a, b) {
      if (a.zoneName && b.zoneName) {
        const x = a.zoneName.toLowerCase();
        const y = b.zoneName.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      } else {
        return 0;
      }
    });

    const zones = objLodash.groupBy(data, "zoneName");

    const allZones = Object.keys(zones);
    let totalByModel = 0;
    let totalByCustomerSource = 0;
    let rowStart,
      rowEnd = 0;
    for (const all of allZones) {
      let zname = "";
      // console.log(`${zones[all].zoneName} @row `, ws.lastRow._number, ' is a first row.');
      rowStart = ws.lastRow._number + 1;
      for (const zone of zones[all]) {
        zname = zone.zoneName;
        // console.log('zone: ', zone );
        let row = ["", zone.zoneName, zone.dealerCode, zone.dealerName];
        for (const source of zone.sources) {
          totalByCustomerSource = 0;
          for (const mo of source.model) {
            totalByModel = 0;
            // totalBySerie = 0;
            for (const se of mo.serie) {
              let totalByCarName = 0;
              let ind = 0;
              for (const ca of se.carNames) {
                // เพิ่มค่ายกเว้น total
                if (ind < se.carNames.length - 1 || se.carNames.length === 1) {
                  // row.push(ca.carValue === 0 ? '' : ca.carValue);
                  row.push(ca.carValue);
                  totalByCarName += ca.carValue;
                }
                ind += 1;
              }
              // อัพเดทค่าคอลัมน์ total ของรถแต่ละรุ่น แต่ต้องมีรุ่นรถมากกว่า 1 รุ่น
              if (ind > 1) {
                row.push(totalByCarName);
              }
              // รวมยอดของแต่ละซีรีย์
              totalByModel += totalByCarName;
            }
            row.push(totalByModel);
            // รวมยอดของแต่ละแหล่งที่มาของลูกค้า
            totalByCustomerSource += totalByModel;
          }
          row.push(totalByCustomerSource);
        }
        ws.addRow(row);
        // Get the last editable row in a worksheet (or undefined if there are none)
        // tslint:disable-next-line: no-shadowed-variable
        const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
        for (let a = 2; a <= cellLength; a++) {
          ws.lastRow.getCell(a).numFmt = "#,##0";
          ws.lastRow.getCell(a).alignment = {
            vertical: "middle",
            horizontal: a > 4 ? "center" : "left",
          };
          ws.lastRow.getCell(a).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
        }
      }
      // add row sum
      const newRow = ws.lastRow;
      rowEnd = newRow._number;
      ws.addRow(newRow);
      // lr.font = { bold: true };
      ws.lastRow.getCell(2).value = "K-Sum";
      ws.lastRow.getCell(3).value = "K-Sum";
      ws.lastRow.getCell(4).value = `${zname} Total`;

      const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
      for (let a = 2; a <= cellLength; a++) {
        ws.lastRow.getCell(a).font = { bold: true };
        ws.lastRow.getCell(a).alignment = {
          vertical: "middle",
          horizontal: a > 4 ? "center" : "left",
        };
        ws.lastRow.getCell(a).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        ws.lastRow.getCell(a).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFF2CC" },
        };
      }

      const rows = ws.getRow(ws.lastRow._number - 1);
      for (let a = 5; a <= rows._cells.length; a++) {
        const col = this.getColumnByNumber(a);
        ws.lastRow.getCell(a).value = {
          formula: `SUM(${col}${rowStart}:${col}${rowEnd})`,
        };
        ws.lastRow.getCell(a).numFmt = "#,##0";
      }
    }
    // Get a row object. If it doesn't already exist, a new empty one will be returned
    // ดึงข้อมูลจากแถวที่ 3 มาเพื่อวนลูป เนื่องจาก eachCell จะไม่หยุดถ้าเจอ empty cell ใน lastRow
    const cells = ws.getRow(7);
    const lastRow = ws.lastRow;
    const rowCarName = 7;
    cells.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      const value = cell.value.toString();
      if (value.toLowerCase().startsWith("total")) {
        const cellRange = [];
        for (let i = rowCarName; i <= lastRow._number; i++) {
          cellRange.push(`${colName}${i}`);
        }
        // cell type 3 = mergecell
        const cellType = cell._value.model.type;
        if (cellType === MERGECELL) {
          cellRange.map((key) => {
            ws.getCell(key).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFF2CC" },
            };
          });
        } else {
          cellRange.map((key) => {
            ws.getCell(key).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFF00" },
            };
          });
        }
      } else if (value.toLowerCase().startsWith("grand")) {
        const cellRange = [];
        for (let i = rowCarName; i <= lastRow._number; i++) {
          cellRange.push(`${colName}${i}`);
        }
        cellRange.map((key) => {
          ws.getCell(key).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC000" },
          };
        });
      }
    });
    // Add grand total
    ws.addRow(["", "", "", "GrandTotal"]);

    const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
    const cellStartColumn = 2;
    for (let a = cellStartColumn; a <= cellLength; a++) {
      ws.lastRow.getCell(a).font = { bold: true };
      ws.lastRow.getCell(a).alignment = {
        vertical: "middle",
        horizontal: a > 4 ? "center" : "left",
      };
      ws.lastRow.getCell(a).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.lastRow.getCell(a).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
      };
    }

    // หมุนแถวที่ 3 เพื่อหา fomular cell
    cells.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      const cellRange = [];
      for (let i = rowCarName; i <= ws.lastRow._number - 1; i++) {
        const cellRow = ws.getCell(`${colName}${i}`);
        const cellType = cellRow._value.model.type;

        if (cellType === FORMULA) {
          // if (cellRange.length > 0) {
          //   cellRange.push(`+${colName}${i}`);
          // } else {
          cellRange.push(`${colName}${i}`);
          // }
        }
      }
      if (colNumber > 4) {
        ws.lastRow.getCell(colNumber).value = {
          formula: `SUM(${cellRange.join("+")})`,
        };
      }
    });

    this.autoSizeColumn(ws, 8);
    // save xlsx file
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, "Prospect – Summary_" + tail + ".xlsx");
    });
  }

  // Function to print Excel column name for a given column number
  private getColumnByNumber(columnNumber: number) {
    const columnName = {
      str: "",
      toString: function () {
        return this.str;
      },
    };
    const _loop_1 = function () {
      const rem_1 = columnNumber % 26;
      if (rem_1 === 0) {
        (function (sb) {
          sb.str = sb.str.concat("Z");
          return sb;
        })(columnName);
        columnNumber = ((columnNumber / 26) | 0) - 1;
      } else {
        /* append */
        (function (sb) {
          sb.str = sb.str.concat(
            String.fromCharCode(rem_1 - 1 + "A".charCodeAt(0))
          );
          return sb;
        })(columnName);
        columnNumber = (columnNumber / 26) | 0;
      }
    };
    while (columnNumber > 0) {
      _loop_1();
    }
    return columnName.str.split("").reverse().join("");
  }

  public exportProspect3ToXlsx(
    sourceData: any,
    carData: any,
    fileName: string,
    dealerName: string,
    dateRange: Date[]
  ) {
    // window.open(URL.createObjectURL(
    //   new Blob([JSON.stringify(carData)], {
    //     type: 'application/binary'
    //   }
    //   )
    // ));

    // let data: SourceReports[] = [];
    const momentRange = extendMoment(moment);
    const start = moment(dateRange[0], "YYYY-MM-DD");
    const end = moment(dateRange[1], "YYYY-MM-DD");
    const ranges = momentRange.range(start, end);
    // ฟังก์ชั่น ranges.diff จะหาส่วนต่างเช่น 1 - 15 จะได้ 14 วัน
    const dayRange = ranges.diff("days") + 1;
    let data: any;
    data = sourceData;
    const REPORT_NAME = "Prospect – Daily Data";
    const tail = `${fileName}_${moment().format("x")}`;
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const ws = wb.addWorksheet(REPORT_NAME);
    // add header
    // const cellA1toD1 = 3;
    let currentRow = 1;
    // add date group row
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    // ws.mergeCells(`C${currentRow}:AI${currentRow}`);
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DEALER";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = dealerName;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    const date: any[] = fileName.split("_");
    ws.getCell(`C${currentRow}`).value = `${moment(date[0]).format(
      "DD MMM YYYY"
    )} - ${moment(date[1]).format("DD MMM YYYY")}`;
    currentRow += 2;
    ws.mergeCells(
      `D${currentRow}:${this.getColumnByNumber(4 + dayRange)}${currentRow}`
    );
    ws.getCell(`D${currentRow}`).value = "วันที่";
    ws.getCell(`D${currentRow}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    ws.getCell(`D${currentRow}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };
    ws.getCell(`D${currentRow}`).font = { bold: true };
    ws.getCell(`D${currentRow}`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFC000" },
    };
    currentRow++;
    // add serie to header
    ws.getCell(`C${currentRow}`).value = "แหล่งที่มา";
    ws.getCell(`C${currentRow}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    ws.getCell(`C${currentRow}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };
    ws.getCell(`C${currentRow}`).font = { bold: true };

    for (let i = 0; i <= dayRange; i++) {
      const col = this.getColumnByNumber(4 + i);
      const day = moment(start).add(i, "d");
      const headTitle = i === dayRange ? "รวม" : `${day.format("D-MMM")}`;
      ws.getCell(`${col}${currentRow}`).value = headTitle;
      ws.getCell(`${col}${currentRow}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${col}${currentRow}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
    }
    // let firstCellNumber = 0;
    for (const src of data) {
      // console.log('source ', src);
      if (src.source === "") {
        // ไม่นับรวมข้อมูลที่ไม่มีแหล่งที่มา
        continue;
      }
      let row: any;
      // const name = src.source === '' ? 'ไม่ระบุ' : src.source;
      const name = src.source;
      row = ["", "", `${name}`];
      let sumRow = 0;
      for (const day of src.days) {
        row.push(day.value);
        sumRow += day.value;
      }
      // row.push(sumRow);
      // const SUM = 0;
      row.push(0);
      // add source to new row
      ws.addRow(row);
      currentRow += 1;
      const firstCellName = ws.lastRow._cells[3]._address;
      // firstCellNumber = ws.lastRow._cells[3]._row._number;
      const sumCell = ws.lastRow._cells[ws.lastRow._cells.length - 1]._address;
      // console.log('sumCell: ', sumCell);
      const lastCellName =
        ws.lastRow._cells[ws.lastRow._cells.length - 2]._address;
      // console.log('sum value: ', t);
      ws.getCell(sumCell).value = {
        formula: `SUM(${firstCellName}:${lastCellName})`,
      };
      // tslint:disable-next-line: no-shadowed-variable
      const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
      for (let a = 3; a <= cellLength; a++) {
        ws.lastRow.getCell(a).alignment = {
          vertical: "middle",
          horizontal: a > 3 ? "center" : "left",
        };
        ws.lastRow.getCell(a).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        ws.lastRow.getCell(a).numFmt = "#,##0";
      }
    }
    // Add grand total
    const newRow = ws.lastRow;
    ws.addRow(newRow);
    currentRow += 1;
    ws.lastRow.font = { bold: true };
    ws.lastRow.getCell(3).value = "รวม";
    // นำแถวก่อน sum มาหาจำนวนคอลัมน์ เนื่องจากถ้านำแถว sum มาจะไม่สามารถวนลูปแถวที่ empty ได้
    const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
    for (let a = 3; a <= cellLength; a++) {
      ws.lastRow.getCell(a).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.lastRow.getCell(a).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.lastRow.getCell(a).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC000" },
      };
      if (a > 3) {
        const col = this.getColumnByNumber(a);
        ws.lastRow.getCell(a).value = {
          formula: `SUM(${col}${7}:${col}${ws.lastRow._number - 1})`,
        };
        ws.lastRow.getCell(a).numFmt = "#,##0";
      }
    }
    // Fill background color to last column (SUM)
    const cells = ws.getRow(7);
    const lastRow = ws.lastRow;
    cells.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      const value = cell.value.toString();
      if (value === "รวม") {
        const cellRange = [];
        for (let i = 3; i <= lastRow._number; i++) {
          cellRange.push(`${colName}${i}`);
        }
        cellRange.map((key) => {
          ws.getCell(key).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC000" }, // FFC000
          };
        });
      }
    });

    currentRow += 2;
    ws.mergeCells(
      `D${currentRow}:${this.getColumnByNumber(4 + dayRange)}${currentRow}`
    );
    ws.getCell(`D${currentRow}`).value = "วันที่";
    ws.getCell(`D${currentRow}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    ws.getCell(`D${currentRow}`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFC000" },
    }; // FBCBA2
    ws.getCell(`D${currentRow}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };
    ws.getCell(`D${currentRow}`).font = { bold: true };
    currentRow += 1;
    // รุ่นรถ -------------| header |--------------------------
    ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`C${currentRow}`).value = "รุ่นรถ";
    ws.getCell(`C${currentRow}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    ws.getCell(`C${currentRow}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };

    for (let i = 0; i <= dayRange; i++) {
      const col = this.getColumnByNumber(4 + i);
      const day = moment(start).add(i, "d");
      const headTitle = i === dayRange ? "รวม" : `${day.format("D-MMM")}`;
      ws.getCell(`${col}${currentRow}`).value = headTitle;
      ws.getCell(`${col}${currentRow}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${col}${currentRow}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
    }

    currentRow += 1;
    let startRow = currentRow;
    let startSerie = currentRow;
    let record = 0;
    let serieName = "";
    let modelName = "";
    let data2: Prospect3List[] = [];
    // let data2: any;
    data2 = carData;
    for (const mo of data2) {
      modelName = mo.model;
      for (const se of mo.series) {
        serieName = se.serie;
        let row: any;
        let sumRow = 0;
        record = 0;
        for (const ca of se.cars) {
          row = ["", ""];
          record += 1;
          row.push(ca.carName);
          sumRow = 0;
          for (const day of ca.days) {
            row.push(day.value);
            sumRow += day.value;
          }
          // ยอดรวมของรถแต่ละคัน
          // const SUM = 0;
          // row.push(SUM);
          row.push(sumRow);
          // add new row
          ws.addRow(row);
          // const firstCellName = ws.lastRow._cells[3]._address;
          // const sumCell =  ws.lastRow._cells[ws.lastRow._cells.length - 1]._address;
          // const lastCellName =  ws.lastRow._cells[ws.lastRow._cells.length - 2]._address;
          // const lastRow = ws.lastRow._number - 1;
          // ws.getCell(sumCell).value = { formula: `SUM(${firstCellName}:${lastCellName})` };
          // tslint:disable-next-line: no-shadowed-variable
          const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
          for (let a = 3; a <= cellLength; a++) {
            ws.lastRow.getCell(a).numFmt = "#,##0";
            ws.lastRow.getCell(a).alignment = {
              vertical: "middle",
              horizontal: a > 3 ? "center" : "left",
            };
            ws.lastRow.getCell(a).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
              color: { argb: "000000" },
            };
          }
        }
        // -----------| add formula : sum   |----------
        if (record > 1) {
          // tslint:disable-next-line: no-shadowed-variable
          const cols = [];
          for (let a = 0; a < ws.lastRow._cells.length; a++) {
            cols.push("");
          }
          ws.addRow(cols);
          ws.lastRow.getCell(3).value = `Total ${se.serie}`;
          ws.lastRow.getCell(3).alignment = {
            vertical: "middle",
            horizontal: "left",
          };
          // นำแถวก่อน sum มาหาจำนวนคอลัมน์ เนื่องจากถ้านำแถว sum มาจะไม่สามารถวนลูปแถวที่ empty ได้
          const rows = ws.getRow(ws.lastRow._number - 1);
          for (let a = 3; a <= rows._cells.length; a++) {
            ws.lastRow.getCell(a).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
              color: { argb: "000000" },
            };
            ws.lastRow.getCell(a).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFF2CC" },
            };
            ws.lastRow.getCell(a).font = { bold: true };
            if (a > 3) {
              ws.lastRow.getCell(a).alignment = {
                vertical: "middle",
                horizontal: "center",
              };
              const col = this.getColumnByNumber(a);
              ws.lastRow.getCell(a).value = {
                formula: `SUM(${col}${startRow}:${col}${ws.lastRow._number - 1
                  })`,
              };
              ws.lastRow.getCell(a).numFmt = "#,##0";
            }
          }
        }
        // -----| end record > 0 and next car|-----
        // -----| Mergecell |-----
        const rec = record > 1 ? record : 0;
        if (rec > 0) {
          ws.mergeCells(`B${startRow}:B${startRow + rec}`);
        }
        ws.getCell(`B${startRow + rec}`).value = serieName;
        ws.getCell(`B${startRow + rec}`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        ws.getCell(`B${startRow + rec}`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        startRow += rec + 1;
      }
      // -----| end serie loop |-----
      // -----| Add Total Serie |------
      const cols = [];
      for (let a = 0; a < ws.lastRow._cells.length; a++) {
        cols.push("");
      }
      ws.addRow(cols);
      ws.mergeCells(`B${startRow}:C${startRow}`);
      ws.getCell(`C${startRow}`).value = `Total ${modelName}`;

      // หมุนแถวเพื่อหา fomular cell
      // const cells = ws.getRow(12);
      // tslint:disable-next-line: no-shadowed-variable
      const cells = ws.getRow(startRow);
      cells.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        const colName = this.getColumnByNumber(colNumber);
        const cellRange = [];
        for (let i = startSerie; i <= ws.lastRow._number - 1; i++) {
          const cellRow = ws.getCell(`${colName}${i}`);
          const cellType = cellRow._value.model.type;

          if (cellType !== FORMULA) {
            cellRange.push(`${colName}${i}`);
          }
        }
        if (colNumber >= 3) {
          ws.lastRow.getCell(colNumber).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
          ws.lastRow.getCell(colNumber).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" },
          };
          ws.lastRow.getCell(colNumber).font = { bold: true };
          ws.lastRow.getCell(colNumber).alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          if (colNumber > 3) {
            ws.lastRow.getCell(colNumber).value = {
              formula: `SUM(${cellRange.join("+")})`,
            };
            ws.lastRow.getCell(colNumber).numFmt = "#,##0";
          }
        }
      });
      startRow += 1;
      startSerie = ws.lastRow._number + 1;
    }
    // -----| Grand Total |-----
    // startRow += 1;
    ws.addRow(["", "", ""]);
    ws.mergeCells(`B${startRow}:C${startRow}`);
    ws.getCell(`C${startRow}`).value = `Grand Total`;
    const lr = ws.lastRow;
    lr.alignment = { vertical: "middle", horizontal: "center" };
    // หมุนแถวที่ currentRow เพื่อหา fomular cell
    const _cells = ws.getRow(currentRow);
    _cells.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      // console.log ('cell info: ', cell);
      const cellRange = [];
      for (let i = currentRow; i <= lr._number - 1; i++) {
        const cellRow = ws.getCell(`${colName}${i}`);
        const cellType = cellRow._value.model.type;

        if (cellType !== FORMULA) {
          cellRange.push(`${colName}${i}`);
        }
      }
      if (colNumber >= 3) {
        ws.lastRow.getCell(colNumber).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        ws.lastRow.getCell(colNumber).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };
        ws.lastRow.getCell(colNumber).font = { bold: true };
        ws.lastRow.getCell(colNumber).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        if (colNumber > 3) {
          lr.getCell(colNumber).value = {
            formula: `SUM(${cellRange.join("+")})`,
          };
          lr.getCell(colNumber).numFmt = "#,##0";
        }
      }
    });
    // -----| End Grand Total |-----
    // Fill background color to last column (SUM)
    const r11 = ws.getRow(6); // ws.getRow(currentRow);
    const _lr = ws.lastRow;
    // _lr.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
    r11.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      const value = cell.value.toString();
      if (value === "รวม") {
        const cellRange = [];
        for (let i = 6; i <= _lr._number; i++) {
          cellRange.push(`${colName}${i}`);
        }
        cellRange.map((key) => {
          // console.log(`cell type: ${cell._value.model.type} mergecell: ${MERGECELL}`)
          // if (cell._value.model.type !== MERGECELL) {
          ws.getCell(key).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC000" }, //  FFC000
          };
          // }
        });
      }
    });

    this.autoSizeColumn(ws, 6);
    // save xlsx file
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, "Prospect – Daily Data_" + tail + ".xlsx");
    });

    // add data list
  }

  public exportBooking3ToXlsx(
    carData: any,
    sourceData: any,
    fileName: string,
    dealerName: string,
    dateRange: Date[]
  ) {
    // console.log(carData);
    // window.open(URL.createObjectURL(
    //   new Blob([JSON.stringify(carData)], {
    //     type: 'application/binary'
    //   }
    //   )
    // ));

    // console.log('data ', carData);
    // console.log( 'source ', sourceData);
    let mergeRol: number;

    const momentRange = extendMoment(moment);
    const start = moment(dateRange[0], "YYYY-MM-DD");
    const end = moment(dateRange[1], "YYYY-MM-DD");
    const ranges = momentRange.range(start, end);
    // ฟังก์ชั่น ranges.diff จะหาส่วนต่างเช่น 1 - 15 จะได้ 14 วัน
    const dayRange = ranges.diff("days") + 1;
    const REPORT_NAME = "Booking – Daily Data";
    const STARTCOLUMN = 2;
    let data: Prospect3List[] = [];
    data = carData;
    let source: any;
    source = sourceData;

    const tail = `${fileName}_${moment().format("x")}`;
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const ws = wb.addWorksheet(REPORT_NAME);
    // add header
    let currentRow = 1;
    const emptyRow = [];
    // for (let a = 0; a < 34; a++) {
    for (let a = 0; a < dayRange + 3; a++) {
      emptyRow.push("");
    }
    // add date group row
    ws.getCell(`A${currentRow}`).value = "REPORT NAME";
    ws.getCell(`A${currentRow}`).font = { bold: true };
    ws.getCell(`B${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`A${currentRow}`).value = "DEALER";
    ws.getCell(`A${currentRow}`).font = { bold: true };
    ws.getCell(`B${currentRow}`).value = dealerName;
    currentRow++;
    ws.getCell(`A${currentRow}`).value = "DATE RANGE";
    ws.getCell(`A${currentRow}`).font = { bold: true };
    const date: any[] = fileName.split("_");
    ws.getCell(`B${currentRow}`).value = `${moment(date[0]).format(
      "DD MMM YYYY"
    )} - ${moment(date[1]).format("DD MMM YYYY")}`;

    currentRow += 2;
    ws.mergeCells(
      `C${currentRow}:${this.getColumnByNumber(3 + dayRange)}${currentRow}`
    );
    ws.getCell(`C${currentRow}`).value = "วันที่";
    ws.getCell(`C${currentRow}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    ws.getCell(`C${currentRow}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };
    ws.getCell(`C${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FBCBA2" },
    };
    currentRow += 1;
    //#region Source Data
    // add serie to header
    ws.getCell(`B${currentRow}`).value = "แหล่งที่มา";
    ws.getCell(`B${currentRow}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    ws.getCell(`B${currentRow}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };
    ws.getCell(`B${currentRow}`).font = { bold: true };

    const START_DATE_COLUMN = 3;
    // for (let i = 0; i <= 31; i++) {
    for (let i = 0; i <= dayRange; i++) {
      const col = this.getColumnByNumber(START_DATE_COLUMN + i);
      const day = moment(start).add(i, "d");
      const headTitle = i === dayRange ? "รวม" : `${day.format("D-MMM")}`;
      ws.getCell(`${col}${currentRow}`).value = headTitle;
      ws.getCell(`${col}${currentRow}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${col}${currentRow}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
    }

    for (const src of source) {
      // if (src.source === '?') {
      //   // ไม่นับรวมข้อมูลที่ไม่มีแหล่งที่มา
      //   continue;
      // }
      // console.log('source ', src);
      let row: any;
      const sourceName = src.source === "?" ? "ไม่ระบุ" : src.source;
      // const sourceName = src.source ;
      row = ["", `${sourceName}`];
      let sumRow = 0;
      for (const day of src.days) {
        row.push(day.value);
        sumRow += day.value;
      }
      // row.push(sumRow);
      // const SUM = 0;
      row.push(0);
      // add source to new row
      ws.addRow(row);
      currentRow += 1;
      // console.log('ws: ', ws.lastRow);
      const firstCellName = ws.lastRow._cells[STARTCOLUMN]._address;
      const sumCell = ws.lastRow._cells[ws.lastRow._cells.length - 1]._address;
      const lastCellName =
        ws.lastRow._cells[ws.lastRow._cells.length - 2]._address;
      // const lastRow = ws.lastRow._number - 1;
      ws.getCell(sumCell).value = {
        formula: `SUM(${firstCellName}:${lastCellName})`,
      };
      // tslint:disable-next-line: no-shadowed-variable
      const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
      for (let a = STARTCOLUMN; a <= cellLength; a++) {
        ws.lastRow.getCell(a).alignment = {
          vertical: "middle",
          horizontal: a > STARTCOLUMN ? "center" : "left",
        };
        ws.lastRow.getCell(a).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        ws.lastRow.getCell(a).numFmt = "#,##0";
      }
    }
    // Add grand total
    const newRow = ws.lastRow;
    ws.addRow(newRow);
    currentRow += 1;
    ws.lastRow.font = { bold: true };
    ws.lastRow.getCell(STARTCOLUMN).value = "รวม";
    // นำแถวก่อน sum มาหาจำนวนคอลัมน์ เนื่องจากถ้านำแถว sum มาจะไม่สามารถวนลูปแถวที่ empty ได้
    const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
    for (let a = STARTCOLUMN; a <= cellLength; a++) {
      ws.lastRow.getCell(a).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.lastRow.getCell(a).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.lastRow.getCell(a).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC000" },
      };
      if (a > STARTCOLUMN) {
        const col = this.getColumnByNumber(a);
        ws.lastRow.getCell(a).value = {
          formula: `SUM(${col}${7}:${col}${ws.lastRow._number - 1})`,
        };
        ws.lastRow.getCell(a).numFmt = "#,##0";
      }
    }
    // Fill background color to last column (SUM)
    const cells = ws.getRow(3);
    const lastRow = ws.lastRow;
    cells.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      const value = cell.value.toString();
      if (value === "รวม") {
        const cellRange = [];
        for (let i = STARTCOLUMN + 1; i <= lastRow._number; i++) {
          cellRange.push(`${colName}${i}`);
        }
        cellRange.map((key) => {
          ws.getCell(key).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC000" },
          };
        });
      }
    });

    // console.log('crow: ', currentRow);
    currentRow += 2;

    //#endregion

    // add the 1st row
    // Row #1
    ws.addRow(emptyRow);
    currentRow++;
    // header Row #2
    ws.addRow(emptyRow);
    currentRow++;
    mergeRol = currentRow;
    // ws.mergeCells(`A${currentRow}:${this.getColumnByNumber(3 + dayRange)}${currentRow}`);
    // ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${currentRow}`).value = 'ยอดจองใหม่ในเดือนปัจจุบัน';
    // ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${currentRow}`).alignment = { vertical: 'middle', horizontal: 'center' };
    // ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    // ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${currentRow}`).font = { bold: true };
    // ws.getRow(currentRow).height = 23.0;
    // ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${currentRow}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '92D050' } };
    // Row #3
    ws.addRow(emptyRow);
    currentRow++;
    // Row #4
    ws.addRow(emptyRow);
    currentRow++;
    // ws.mergeCells(`C${currentRow}:AH${currentRow}`);
    ws.mergeCells(
      `C${currentRow}:${this.getColumnByNumber(3 + dayRange)}${currentRow}`
    );
    ws.getCell(`C${currentRow}`).value = "วันที่";
    ws.getCell(`C${currentRow}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    ws.getCell(`C${currentRow}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };
    ws.getCell(`C${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FBCBA2" },
    };
    currentRow++;
    // ws.mergeCells(`A${currentRow}:B${currentRow}`);
    // tslint:disable-next-line: no-shadowed-variable
    const mCell = `A${currentRow}:B${currentRow}`;
    // console.log(`${mCell} start merge...#2`);
    ws.mergeCells(mCell);
    ws.getCell(`B${currentRow}`).value = "รุ่นรถ";
    ws.getCell(`B${currentRow}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    ws.getRow(2).height = 15.0;
    for (let i = 0; i <= dayRange; i++) {
      const col = this.getColumnByNumber(START_DATE_COLUMN + i);
      const day = moment(start).add(i, "d");
      const headTitle = i === dayRange ? "รวม" : `${day.format("D-MMM")}`;
      ws.getCell(`${col}${currentRow}`).value = headTitle;
      ws.getCell(`${col}${currentRow}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${col}${currentRow}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
    }

    //currentRow++;

    let startRow = currentRow + 1;
    let startSerie = currentRow + 1;
    let record = 0;
    let serieName = "";
    let modelName = "";
    // let data2: Prospect3List[] = [];
    // let data2: any;
    // data2 = carData;
    for (const mo of data) {
      modelName = mo.model;
      for (const se of mo.series) {
        serieName = se.serie === "?" ? "ไม่ระบุ" : se.serie;
        let row: any;
        let sumRow = 0;
        record = 0;
        for (const ca of se.cars) {
          row = [""];
          record += 1;
          row.push(ca.carName);
          sumRow = 0;
          for (const day of ca.days) {
            row.push(day.value);
            sumRow += day.value;
          }
          // ยอดรวมของรถแต่ละคัน
          row.push(sumRow);
          // add new row
          ws.addRow(row);
          // startRow++;
          // tslint:disable-next-line: no-shadowed-variable
          const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
          for (let a = STARTCOLUMN; a <= cellLength; a++) {
            ws.lastRow.getCell(a).numFmt = "#,##0";
            ws.lastRow.getCell(a).alignment = {
              vertical: "middle",
              horizontal: a !== 2 ? "center" : "left",
            };
            ws.lastRow.getCell(a).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
              color: { argb: "000000" },
            };
            // ws.getRow(a).height = 15.0;
          }
        }
        // -----------| add formula : sum   |----------
        if (record > 1) {
          // tslint:disable-next-line: no-shadowed-variable
          const cols = [];
          for (let a = 0; a < ws.lastRow._cells.length; a++) {
            cols.push("");
          }
          ws.addRow(cols);
          // startRow++;
          ws.lastRow.getCell(2).value = `Total ${se.serie}`;
          ws.lastRow.getCell(2).alignment = {
            vertical: "middle",
            horizontal: "left",
          };
          // ws.lastRow.getCell(2).height = 15.0;
          // นำแถวก่อน sum มาหาจำนวนคอลัมน์ เนื่องจากถ้านำแถว sum มาจะไม่สามารถวนลูปแถวที่ empty ได้
          const rows = ws.getRow(ws.lastRow._number - 1);
          for (let a = STARTCOLUMN; a <= rows._cells.length; a++) {
            ws.lastRow.getCell(a).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
              color: { argb: "000000" },
            };
            ws.lastRow.getCell(a).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFF2CC" },
            };
            ws.lastRow.getCell(a).font = { bold: true };
            if (a > 2) {
              ws.lastRow.getCell(a).alignment = {
                vertical: "middle",
                horizontal: "center",
              };
              const col = this.getColumnByNumber(a);
              ws.lastRow.getCell(a).value = {
                formula: `SUM(${col}${startRow}:${col}${ws.lastRow._number - 1
                  })`,
              };
              ws.lastRow.getCell(a).numFmt = "#,##0";
            }
          }
        }
        // -----| end record > 0 and next car|-----
        // -----| Mergecell |-----
        const rec = record > 1 ? record : 0;
        if (rec > 0) {
          // tslint:disable-next-line: no-shadowed-variable
          const mergeCell = `A${startRow}:A${startRow + rec}`;
          // console.log(`${mergeCell} start merge...`);
          ws.mergeCells(mergeCell);
        }
        ws.getCell(`A${startRow + rec}`).value = serieName;
        ws.getCell(`A${startRow + rec}`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        ws.getCell(`A${startRow + rec}`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        // ws.getCell(`A${startRow + rec}`).height = 15.0;
        startRow += rec + 1;
      }
      // -----| end serie loop |-----
      // -----| Add Total Serie |------
      const cols = [];
      for (let a = 0; a < ws.lastRow._cells.length; a++) {
        cols.push("");
      }
      ws.addRow(cols);
      // tslint:disable-next-line: no-shadowed-variable
      const mergeCell = `A${startRow}:B${startRow}`;
      // console.log(`${mergeCell} start merge...`);
      ws.mergeCells(mergeCell);
      // console.log('merge cell: ', `A${startRow}:B${startRow}`);
      ws.getCell(`B${startRow}`).value = `Total ${modelName}`;
      // ws.getCell(`B${startRow}`).height = 15.0;

      // หมุนแถวเพื่อหา fomular cell
      // const cells = ws.getRow(12);
      // tslint:disable-next-line: no-shadowed-variable
      const cells = ws.getRow(startRow);
      cells.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        const colName = this.getColumnByNumber(colNumber);
        const cellRange = [];
        for (let i = startSerie; i <= ws.lastRow._number - 1; i++) {
          const cellRow = ws.getCell(`${colName}${i}`);
          const cellType = cellRow._value.model.type;

          if (cellType !== FORMULA) {
            cellRange.push(`${colName}${i}`);
          }
        }
        if (colNumber >= 2) {
          ws.lastRow.getCell(colNumber).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
          ws.lastRow.getCell(colNumber).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" },
          };
          ws.lastRow.getCell(colNumber).font = { bold: true };
          ws.lastRow.getCell(colNumber).alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          if (colNumber > 2) {
            ws.lastRow.getCell(colNumber).value = {
              formula: `SUM(${cellRange.join("+")})`,
            };
            ws.lastRow.getCell(colNumber).numFmt = "#,##0";
          }
        }
      });
      startRow += 1;
      startSerie = ws.lastRow._number + 1;
    }
    // -----| Grand Total |-----
    // startRow += 1;
    ws.addRow(["", "", ""]);
    const mergeCell = `A${startRow}:B${startRow}`;
    // console.log(`${mergeCell} start merge...`);
    ws.mergeCells(mergeCell);
    ws.getCell(`B${startRow}`).value = `Grand Total`;
    const lr = ws.lastRow;
    lr.alignment = { vertical: "middle", horizontal: "center" };
    // หมุนแถวที่ 5 เพื่อหา fomular cell
    const _cells = ws.getRow(currentRow + 1);
    _cells.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      // console.log ('cell info: ', cell);
      const cellRange = [];
      // for (let i = 5; i <= lr._number - 1; i++) {
      for (let i = currentRow + 1; i <= lr._number - 1; i++) {
        const cellRow = ws.getCell(`${colName}${i}`);
        const cellType = cellRow._value.model.type;

        if (cellType !== FORMULA) {
          cellRange.push(`${colName}${i}`);
        }
      }
      if (colNumber >= 2) {
        ws.lastRow.getCell(colNumber).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        ws.lastRow.getCell(colNumber).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };
        ws.lastRow.getCell(colNumber).font = { bold: true };
        ws.lastRow.getCell(colNumber).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        if (colNumber > 2) {
          lr.getCell(colNumber).value = {
            formula: `SUM(${cellRange.join("+")})`,
          };
          lr.getCell(colNumber).numFmt = "#,##0";
        }
      }
    });
    // -----| End Grand Total |-----
    // Fill background color to last column (SUM)
    // console.log('cur row: ', currentRow);
    const r11 = ws.getRow(currentRow);
    const _lr = ws.lastRow;
    // _lr.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' } };
    r11.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      const value = cell.value.toString();
      if (value === "รวม") {
        const cellRange = [];
        // เติมสีเหลืองตั้งแต่แถวที่ 4 ลงจนสุดแถว
        for (let i = currentRow; i <= _lr._number; i++) {
          cellRange.push(`${colName}${i}`);
        }
        cellRange.map((key) => {
          ws.getCell(key).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC000" },
          };
        });
      }
    });

    // data.sort(function (a, b) {
    //   if (a.zoneName && b.zoneName) {
    //     const x = a.zoneName.toLowerCase();
    //     const y = b.zoneName.toLowerCase();
    //     if (x < y) { return -1; }
    //     if (x > y) { return 1; }
    //     return 0;
    //   } else {
    //     return 0;
    //   }
    // });

    this.autoSizeColumn(ws, 6);
    ws.mergeCells(
      `A${mergeRol}:${this.getColumnByNumber(3 + dayRange)}${mergeRol}`
    );
    ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${mergeRol}`).value =
      "ยอดจองใหม่ในเดือนปัจจุบัน";
    ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${mergeRol}`).alignment =
      { vertical: "middle", horizontal: "center" };
    ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${mergeRol}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };
    ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${mergeRol}`).font = {
      bold: true,
    };
    ws.getRow(mergeRol).height = 23.0;
    ws.getCell(`${this.getColumnByNumber(3 + dayRange)}${mergeRol}`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "92D050" },
    };

    // save xlsx file
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, "Booking – Daily Data_" + tail + ".xlsx");
    });

    // add data list
  }

  public exportBooking2ToXlsx(
    headers: Source[],
    data: any,
    fileName: string,
    dealerName: string
  ) {
    // console.log('bf data: ', JSON.parse(JSON.stringify(data)));

    //this.calculateGrandTotal(data);
    // console.log('af data: ', JSON.parse(JSON.stringify(data)) );

    const REPORT_NAME = "Booking – Summary";
    const tail = `${fileName}_${moment().format("x")}`;
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const ws = wb.addWorksheet(REPORT_NAME);

    // insert report name
    let currentRow = 1;
    // add date group row
    ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    // ws.getCell(`B${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`D${currentRow}`).value = REPORT_NAME;
    // ws.getCell(`D${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    currentRow++; // row #2
    ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`B${currentRow}`).value = "DEALER";
    // ws.getCell(`B${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`D${currentRow}`).value = dealerName;
    // ws.getCell(`D${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    currentRow++; // row #3
    ws.mergeCells(`B${currentRow}:C${currentRow}`);
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    // ws.getCell(`B${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };
    ws.getCell(`B${currentRow}`).font = { bold: true };
    const date: any[] = fileName.split("_");
    ws.getCell(`D${currentRow}`).value = `${moment(date[0]).format(
      "DD MMM YYYY"
    )} - ${moment(date[1]).format("DD MMM YYYY")}`;
    // ws.getCell(`D${currentRow}`).border = {
    //   top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, color: { argb: '000000' }
    // };

    // currentRow += 2;  // row #5
    // add header
    const cellA1toD1 = 4;
    const colGrandTotal = 1;
    // Customer Source Variable
    let startCustomerSourceColName = "";
    let startCustomerSourceCellMerge = 0;
    let stopCustomerSourceCellMerge = 0;
    // Series Variable
    let startSerieCellMergeName = "";
    let startSerieCellMerge = cellA1toD1;
    let stopSerieCellMerge = cellA1toD1;
    // Cars variable
    let startCarsCellMerge = cellA1toD1;
    let loop = 0;
    // let startGrandtotal = 0;
    for (let i = 0; i < headers.length; i++) {
      // add cellmerge customer source.
      loop += 1;
      startCustomerSourceCellMerge =
        loop === 1 ? cellA1toD1 + 1 : stopCustomerSourceCellMerge + 1;
      startCustomerSourceColName = this.getColumnByNumber(
        startCustomerSourceCellMerge
      );
      stopCustomerSourceCellMerge =
        headers[i].cellMerge * loop + cellA1toD1 + colGrandTotal + i;

      const col = this.getColumnByNumber(stopCustomerSourceCellMerge);
      const colrange = `${startCustomerSourceColName}5:${col}5`;
      ws.mergeCells(colrange);
      ws.getCell(`${col}5`).value = headers[i].sourceName;
      ws.getCell(`${col}5`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${col}5`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: loop % 2 === 0 ? "F79646" : "92D050" },
      };
      ws.getCell(`${col}5`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.getCell(`${col}5`).font = { bold: true };
      // add serie to header
      ws.getCell("D6").value = "Series";
      ws.getCell("D6").alignment = { vertical: "middle", horizontal: "center" };
      ws.getCell("D6").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00B0F0" },
      };
      ws.getCell("D6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.getCell("B7").value = "Zone";
      ws.getCell("B7").alignment = { vertical: "middle", horizontal: "center" };
      ws.getCell("B7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.getCell("C7").value = "Dlr Code";
      ws.getCell("C7").alignment = { vertical: "middle", horizontal: "center" };
      ws.getCell("C7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.getCell("D7").value = "Dlr Name";
      ws.getCell("D7").alignment = { vertical: "middle", horizontal: "center" };
      ws.getCell("D7").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      // Start model loop
      for (const mo of headers[i].model) {
        const modelName = mo.modelName;
        let serieName = "";
        // Start series loop
        for (const se of mo.serie) {
          serieName = se.serieName;
          // console.log(`${serieName} cellSerieMerge bf + ${se.cellMerge} + 4 : ${cellSerieMerge}`);
          startSerieCellMerge =
            startSerieCellMerge === cellA1toD1
              ? cellA1toD1 + 1
              : stopSerieCellMerge + 1;
          startSerieCellMergeName = this.getColumnByNumber(startSerieCellMerge);
          // console.log(`cellSerieMerge af + ${se.cellMerge} + 4 : ${cellSerieMerge}`);
          stopSerieCellMerge += se.cellMerge;
          const stopSerieCellMergeName =
            this.getColumnByNumber(stopSerieCellMerge);
          const colSerieRange = `${startSerieCellMergeName}6:${stopSerieCellMergeName}6`;
          ws.mergeCells(colSerieRange);
          ws.getCell(`${stopSerieCellMergeName}6`).value = serieName;
          ws.getCell(`${stopSerieCellMergeName}6`).alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          ws.getCell(`${stopSerieCellMergeName}6`).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
          // Start car name loop
          let ind = 0;
          for (const ca of se.carNames) {
            startCarsCellMerge += 1;
            const stopCarsCellMergeName =
              this.getColumnByNumber(startCarsCellMerge);
            ws.getCell(`${stopCarsCellMergeName}7`).value = ca.carName;
            if (ind === se.carNames.length - 1 && ind > 0) {
              ws.getCell(`${stopCarsCellMergeName}7`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFF2CC" },
              }; // สีเหลือง
            }
            ws.getCell(`${stopCarsCellMergeName}7`).alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
            ws.getCell(`${stopCarsCellMergeName}7`).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
              color: { argb: "000000" },
            };

            ind += 1;
          }
        }
        startCarsCellMerge += 1;
        // add total each modelName
        const colTotalModelName = this.getColumnByNumber(
          stopSerieCellMerge + 1
        );
        const colTotalModelRange = `${colTotalModelName}6:${colTotalModelName}7`;
        ws.mergeCells(colTotalModelRange);
        ws.getCell(`${colTotalModelName}6`).value = `Total\r\n${modelName}`;
        ws.getCell(`${colTotalModelName}6`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        ws.getCell(`${colTotalModelName}6`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFF2CC" },
        }; // สีเหลือง
        ws.getCell(`${colTotalModelName}6`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        stopSerieCellMerge += 1;
      }
      // Grand Total
      const colGrandTotalModelName = this.getColumnByNumber(
        stopSerieCellMerge + 1
      );
      const colGrandTotalModelRange = `${colGrandTotalModelName}6:${colGrandTotalModelName}7`;
      ws.mergeCells(colGrandTotalModelRange);
      ws.getCell(`${colGrandTotalModelName}6`).value = `Grand\r\nTotal`;
      ws.getCell(`${colGrandTotalModelName}6`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${colGrandTotalModelName}6`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC000" },
      }; // สีเหลืองเข้ม
      ws.getCell(`${colGrandTotalModelName}6`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      stopSerieCellMerge += 1;
      startCarsCellMerge += 1;
    }

    data.sort(function (a, b) {
      if (a.zoneName && b.zoneName) {
        const x = a.zoneName.toLowerCase();
        const y = b.zoneName.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      } else {
        return 0;
      }
    });

    const zones = objLodash.groupBy(data, "zoneName");

    const allZones = Object.keys(zones);
    let totalByModel = 0;
    let totalByCustomerSource = 0;
    let rowStart,
      rowEnd = 0;
    for (const all of allZones) {
      let zname = "";
      // console.log(`${zones[all].zoneName} @row `, ws.lastRow._number, ' is a first row.');
      rowStart = ws.lastRow._number + 1;
      for (const zone of zones[all]) {
        zname = zone.zoneName;
        // console.log('zone: ', zone );
        let row = ["", zone.zoneName, zone.dealerCode, zone.dealerName];
        for (const source of zone.sources) {
          totalByCustomerSource = 0;
          for (const mo of source.model) {
            totalByModel = 0;
            // totalBySerie = 0;
            for (const se of mo.serie) {
              let totalByCarName = 0;
              let ind = 0;
              for (const ca of se.carNames) {
                // เพิ่มค่ายกเว้น total
                if (ind < se.carNames.length - 1 || se.carNames.length === 1) {
                  // row.push(ca.carValue === 0 ? '' : ca.carValue);
                  row.push(ca.carValue);
                  totalByCarName += ca.carValue;
                }
                ind += 1;
              }
              // อัพเดทค่าคอลัมน์ total ของรถแต่ละรุ่น แต่ต้องมีรุ่นรถมากกว่า 1 รุ่น
              if (ind > 1) {
                row.push(totalByCarName);
              }
              // รวมยอดของแต่ละซีรีย์
              totalByModel += totalByCarName;
            }
            row.push(totalByModel);
            // รวมยอดของแต่ละแหล่งที่มาของลูกค้า
            totalByCustomerSource += totalByModel;
          }
          row.push(totalByCustomerSource);
        }
        ws.addRow(row);
        // Get the last editable row in a worksheet (or undefined if there are none)
        const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
        for (let a = 2; a <= cellLength; a++) {
          ws.lastRow.getCell(a).numFmt = "#,##0";
          ws.lastRow.getCell(a).alignment = {
            vertical: "middle",
            horizontal: a > 4 ? "center" : "left",
          };
          ws.lastRow.getCell(a).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
        }
      }
      // add row sum
      const newRow = ws.lastRow;
      rowEnd = newRow._number;
      ws.addRow(newRow);
      ws.lastRow.getCell(2).value = "K-Sum";
      ws.lastRow.getCell(3).value = "K-Sum";
      ws.lastRow.getCell(4).value = `${zname} Total`;
      const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
      for (let a = 2; a <= cellLength; a++) {
        ws.lastRow.getCell(a).alignment = {
          vertical: "middle",
          horizontal: a > 4 ? "center" : "left",
        };
        ws.lastRow.getCell(a).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
        ws.lastRow.getCell(a).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFF2CC" },
        };
        ws.lastRow.getCell(a).font = { bold: true };
      }
      const rows = ws.getRow(ws.lastRow._number - 1);
      for (let a = 5; a <= rows._cells.length; a++) {
        const col = this.getColumnByNumber(a);
        ws.lastRow.getCell(a).value = {
          formula: `SUM(${col}${rowStart}:${col}${rowEnd})`,
        };
        ws.lastRow.getCell(a).numFmt = "#,##0";
      }
    }
    // Get a row object. If it doesn't already exist, a new empty one will be returned
    // ดึงข้อมูลจากแถวที่ 7 มาเพื่อวนลูป เนื่องจาก eachCell จะไม่หยุดถ้าเจอ empty cell ใน lastRow
    const cells = ws.getRow(7);
    const lastRow = ws.lastRow;
    const rowCarName = 7;
    cells.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      const value = cell.value.toString();
      if (value.toLowerCase().startsWith("total")) {
        const cellRange = [];
        for (let i = rowCarName; i <= lastRow._number; i++) {
          cellRange.push(`${colName}${i}`);
        }
        // cell type 3 = mergecell
        const cellType = cell._value.model.type;
        if (cellType === MERGECELL) {
          cellRange.map((key) => {
            ws.getCell(key).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFF2CC" },
            };
          });
        } else {
          cellRange.map((key) => {
            ws.getCell(key).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFF00" },
            };
          });
        }
      } else if (value.toLowerCase().startsWith("grand")) {
        const cellRange = [];
        for (let i = rowCarName; i <= lastRow._number; i++) {
          cellRange.push(`${colName}${i}`);
        }
        cellRange.map((key) => {
          ws.getCell(key).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC000" },
          };
        });
      }
    });
    // Add grand total
    ws.addRow(["", "", "", "GrandTotal"]);
    const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
    const cellStartColumn = 2;
    for (let a = cellStartColumn; a <= cellLength; a++) {
      ws.lastRow.getCell(a).alignment = {
        vertical: "middle",
        horizontal: a > 4 ? "center" : "left",
      };
      ws.lastRow.getCell(a).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        color: { argb: "000000" },
      };
      ws.lastRow.getCell(a).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
      };
      ws.lastRow.getCell(a).font = { bold: true };
    }
    // หมุนแถวที่ 3 เพื่อหา fomular cell
    cells.eachCell((cell, colNumber) => {
      const colName = this.getColumnByNumber(colNumber);
      // console.log ('cell info: ', cell);
      const cellRange = [];
      for (let i = rowCarName; i <= ws.lastRow._number - 1; i++) {
        const cellRow = ws.getCell(`${colName}${i}`);
        const cellType = cellRow._value.model.type;

        if (cellType === FORMULA) {
          // if (cellRange.length > 0) {
          //   cellRange.push(`+${colName}${i}`);
          // } else {
          cellRange.push(`${colName}${i}`);
          // }
        }
      }
      if (colNumber > 4) {
        // console.log('col: ', colNumber, ' formula: ', 'SUM(', cellRange.join('+'), ')');
        ws.lastRow.getCell(colNumber).value = {
          formula: `SUM(${cellRange.join("+")})`,
        };
        ws.lastRow.getCell(colNumber).numFmt = "#,##0";
      }
    });

    /* add comment to Out of production *
    3.2LTitanium 4x4 AT
    DBL 3.2L XLT 4x4 AT
    Open Cab 2.0L Turbo Limited 4x4 6MT
    Open Cab 2.2L XLS 4x2 HR 6AT
    Double Cab 2.2L XLS 4x2 HR 6MT
    Open Cab 2.2L XL 4x2 LR 6MT (Sport Pack)
    */

    this.autoSizeColumn(ws, 8);

    // save xlsx file
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, "Booking – Summary_" + tail + ".xlsx");
    });
  }

  public exportOverall2ToXlsx(
    theBatch: OverAllData,
    fileName: string,
    dealerName: string
  ) {
    const REPORT_NAME = "CRM Overall";
    const tail = `${fileName}_${moment().format("x")}`;
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const ws = wb.addWorksheet(REPORT_NAME);
    const emptyRow = ["", ""];
    for (let a = 0; a < theBatch.dayLength; a++) {
      emptyRow.push("");
    }
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DEALER";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = dealerName;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    const date: any[] = fileName.split("_");
    ws.getCell(`C${currentRow}`).value = `${moment(date[0]).format(
      "DD MMM YYYY"
    )} - ${moment(date[1]).format("DD MMM YYYY")}`;

    // add header
    ws.addRow(emptyRow);
    ws.addRow(emptyRow);
    const lastColName = this.getColumnByNumber(emptyRow.length);
    const colrange = `B6:${lastColName}6`;
    ws.mergeCells(colrange);
    ws.getCell(`${lastColName}6`).value = theBatch.batchname;
    ws.getCell(`${lastColName}6`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    ws.getCell(`${lastColName}6`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4472C4" },
    };
    ws.getCell(`${lastColName}6`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };
    ws.getCell(`${lastColName}6`).font = {
      bold: true,
      color: { argb: "FFFFFF" },
    };
    // ----- add row header Dealer -----
    {
      ws.addRow(emptyRow);
      const row = ws.lastRow;
      row.getCell(2).value = "Dealer";
      this.rowFormat(row, 2, "left");
      // ค้นหา index ที่มีค่ามากที่สุด
      let index,
        max = 0;
      for (let a = 0; a < theBatch.dealer.length; a++) {
        if (theBatch.dealer[a].dates.length > max) {
          max = theBatch.dealer[a].dates.length;
          index = a;
        }
      }

      for (let a = 0; a < theBatch.dayLength; a++) {
        const col = a + 3;
        if (theBatch.dealer.length === 0) {
          continue;
        } else {
          if (theBatch.dealer[index].dates.length >= a + 1) {
            row.getCell(col).value = theBatch.dealer[index].dates[a].date;
          }
          this.rowFormat(row, col);
        }
      }
      // add dealer content batch2
      for (let a = 0; a < theBatch.dealer.length; a++) {
        // tslint:disable-next-line: no-shadowed-variable
        const row = [];
        row.push("", `${theBatch.dealer[a].code} - ${theBatch.dealer[a].name}`);
        for (let b = 0; b < theBatch.dealer[a].dates.length; b++) {
          row.push(theBatch.dealer[a].dates[b].value);
        }
        ws.addRow(row);
        // format last row
        const lr = ws.lastRow;
        // this.rowFormat(lr, 2, 'left');
        for (let b = 0; b <= theBatch.dayLength; b++) {
          const col = b + 2;
          if (col === 2) {
            lr.getCell(col).alignment = {
              vertical: "middle",
              horizontal: "left",
            };
          } else {
            lr.getCell(col).alignment = {
              vertical: "middle",
              horizontal: "center",
            };
            lr.getCell(col).numFmt = "#,##0";
          }
          lr.getCell(col).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
        }
      }
      ws.addRow(emptyRow);
      for (let b = 0; b <= theBatch.dayLength; b++) {
        const col = b + 2;
        ws.lastRow.getCell(col).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        ws.lastRow.getCell(col).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
      }
    }

    // ----- add row header Media -----
    {
      ws.addRow(emptyRow);
      const row = ws.lastRow;
      row.getCell(2).value = "Media Source";
      this.rowFormat(row, 2, "left");
      // ค้นหา index ที่มีค่ามากที่สุด
      let index,
        max = 0;
      for (let a = 0; a < theBatch.media.length; a++) {
        if (theBatch.media[a].dates.length > max) {
          max = theBatch.media[a].dates.length;
          index = a;
        }
      }
      // console.log('batch: ', theBatch.dayLength);
      for (let a = 0; a < theBatch.dayLength; a++) {
        const col = a + 3;
        if (theBatch.media.length === 0) {
          continue;
        } else {
          if (theBatch.media[index].dates.length > a) {
            row.getCell(col).value = theBatch.media[index].dates[a].date;
          }
          this.rowFormat(row, col);
        }
      }
      // add media content theBatch
      for (let a = 0; a < theBatch.media.length; a++) {
        // tslint:disable-next-line: no-shadowed-variable
        const row = [];
        row.push("", theBatch.media[a].name);
        for (let b = 0; b < theBatch.media[a].dates.length; b++) {
          row.push(theBatch.media[a].dates[b].value);
        }
        ws.addRow(row);
        // format last row
        const lr = ws.lastRow;
        for (let b = 0; b <= theBatch.dayLength; b++) {
          const col = b + 2;
          if (col === 2) {
            lr.getCell(col).alignment = {
              vertical: "middle",
              horizontal: "left",
            };
          } else {
            lr.getCell(col).alignment = {
              vertical: "middle",
              horizontal: "center",
            };
            lr.getCell(col).numFmt = "#,##0";
          }
          lr.getCell(col).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
        }
      }
      ws.addRow(emptyRow);
      for (let b = 0; b <= theBatch.dayLength; b++) {
        const col = b + 2;
        ws.lastRow.getCell(col).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        ws.lastRow.getCell(col).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
      }
    }

    // ----- add row header Lead Source -----
    {
      ws.addRow(emptyRow);
      const row = ws.lastRow;
      row.getCell(2).value = "Lead Source";
      this.rowFormat(row, 2, "left");
      // ค้นหา index ที่มีค่ามากที่สุด
      let index,
        max = 0;
      for (let a = 0; a < theBatch.lead.length; a++) {
        if (theBatch.lead[a].dates.length > max) {
          max = theBatch.lead[a].dates.length;
          index = a;
        }
      }
      for (let a = 0; a < theBatch.dayLength; a++) {
        const col = a + 3;
        if (theBatch.lead.length === 0) {
          continue;
        } else {
          if (theBatch.lead[index].dates.length > a) {
            row.getCell(col).value = theBatch.lead[index].dates[a].date;
          }
          this.rowFormat(row, col);
        }
      }
      // add lead source content theBatch
      for (let a = 0; a < theBatch.lead.length; a++) {
        // tslint:disable-next-line: no-shadowed-variable
        const row = [];
        row.push("", theBatch.lead[a].name);
        for (let b = 0; b < theBatch.lead[a].dates.length; b++) {
          row.push(theBatch.lead[a].dates[b].value);
        }
        ws.addRow(row);
        // format last row
        const lr = ws.lastRow;
        for (let b = 0; b <= theBatch.dayLength; b++) {
          const col = b + 2;
          if (col === 2) {
            lr.getCell(col).alignment = {
              vertical: "middle",
              horizontal: "left",
            };
          } else {
            lr.getCell(col).alignment = {
              vertical: "middle",
              horizontal: "center",
            };
            lr.getCell(col).numFmt = "#,##0";
          }
          lr.getCell(col).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
        }
      }
      ws.addRow(emptyRow);
      for (let b = 0; b <= theBatch.dayLength; b++) {
        const col = b + 2;
        ws.lastRow.getCell(col).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        ws.lastRow.getCell(col).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          color: { argb: "000000" },
        };
      }
    }

    this.autoSizeColumn(ws, 2);
    // save xlsx file
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, "Marketing-Report-CRM-Overall_" + tail + ".xlsx");
    });
  }

  public exportDealerFollowUpLeadToXlsx(
    data: MarkettingList[],
    header: MarkettingSource[],
    fileName: string,
    dealerName: string
  ) {
    const REPORT_NAME = "Dealer Follow Up Lead";
    const tail = `${fileName}_${moment().format("x")}`;
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const ws = wb.addWorksheet(REPORT_NAME);
    const emptyRow = ["", "", "", ""];
    // Prospect
    for (let a = 0; a < header[0].cellMerge; a++) {
      emptyRow.push("");
    }
    // Dealer Contact Status
    for (let a = 0; a < header[1].cellMerge; a++) {
      emptyRow.push("");
    }
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DEALER";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = dealerName;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    const date: any[] = fileName.split("_");
    ws.getCell(`C${currentRow}`).value = `${moment(date[0]).format(
      "DD MMM YYYY"
    )} - ${moment(date[1]).format("DD MMM YYYY")}`;

    // add header
    ws.addRow(emptyRow);
    ws.addRow(emptyRow);
    ws.addRow(emptyRow);
    const colrange = `B6:C7`;
    ws.mergeCells(colrange);
    ws.getCell(`C6`).value = "Assigned Dealer";
    this.rowFormat(ws.getRow(6), 3);
    ws.addRow(emptyRow);
    ws.getCell("B8").value = "Dealer Code";
    this.rowFormat(ws.getRow(8), 2);
    ws.getCell("C8").value = "Dealer Name";
    this.rowFormat(ws.getRow(8), 3);
    // ----- add row header Dealer -----
    {
      let startModelCell = 4;
      // เก็บตำแหน่งคอลัมน์ไว้ เพื่อนำไปใช้ตอน a = 1;
      let currentColumn = 0;
      for (let a = 0; a < header.length; a++) {
        if (a === 0) {
          const colsMerge = header[a].cellMerge + 3;
          const theColumnName = this.getColumnByNumber(colsMerge);
          const cr = `D6:${theColumnName}6`;
          currentColumn += colsMerge;
          ws.mergeCells(cr);
          ws.getCell(`${theColumnName}6`).value = header[a].sourceName;
          this.rowFormat(ws.getRow(6), colsMerge, "center", "8FAADC");
          const colNameTotal = this.getColumnByNumber(colsMerge + 1);
          ws.mergeCells(`${colNameTotal}6:${colNameTotal}8`);
          ws.getCell(
            `${colNameTotal}8`
          ).value = `Total ${header[a].sourceName}`;
          this.rowFormat(ws.getRow(8), colsMerge + 1, "center", "8FAADC");
          // หัวคอลัมน์ New Lead (LMS)* | Ford provide Lead (SAP)**
          let startCell = 3;
          for (let b = 0; b < header[a].modelHeader.length; b++) {
            const mergeCol = header[a].modelHeader[b].cellMerge + startCell;
            const colName = this.getColumnByNumber(mergeCol);
            const startCellName = this.getColumnByNumber(startCell + 1);
            const colRange = `${startCellName}7:${colName}7`;
            // console.log(`startCell: ${startCell} lastMergeCol: ${mergeCol} header will merge: ${colRange}`);
            ws.mergeCells(colRange);
            ws.getCell(`${colName}7`).value =
              header[a].modelHeader[b].headerName;
            this.rowFormat(ws.getRow(7), mergeCol, "center", "8FAADC");
            startCell += header[a].modelHeader[b].cellMerge;
            // หัวคอลัมน์ที่เป็นชื่อโมเดลรถ
            for (let c = 0; c < header[a].modelHeader[b].models.length; c++) {
              const startModelCellName = this.getColumnByNumber(startModelCell);
              ws.getCell(`${startModelCellName}8`).value =
                header[a].modelHeader[b].models[c].modelName;
              this.rowFormat(ws.getRow(8), startModelCell, "center", "8FAADC");
              startModelCell += 1;
            }
          }
        } else {
          const totalCol = 1;
          const nextColumn = currentColumn + totalCol;
          const colsMerge = nextColumn + header[a].cellMerge;
          const beginColName = this.getColumnByNumber(nextColumn + 1);
          const endColName = this.getColumnByNumber(colsMerge);
          const mergeRange = `${beginColName}6:${endColName}6`;
          // console.log(`nextcol: ${nextColumn} cellMerge: ${header[a].cellMerge} start col x2:y2 = ${beginColName}2:${endColName}2`);
          ws.mergeCells(mergeRange);
          ws.getCell(`${endColName}6`).value = header[a].sourceName;
          this.rowFormat(ws.getRow(6), nextColumn + 1, "center", "FFE699");
          // Total Merge Cell Column
          const colNameTotal = this.getColumnByNumber(colsMerge + 1);
          // console.log(`merging cell = ${colNameTotal}2:${colNameTotal}4`);
          ws.mergeCells(`${colNameTotal}6:${colNameTotal}8`);
          ws.getCell(
            `${colNameTotal}8`
          ).value = `Total ${header[a].sourceName}`;
          this.rowFormat(ws.getRow(8), colsMerge + 1, "center", "FFE699");
          // หัวคอลัมน์ New Lead (LMS)* | Ford provide Lead (SAP)**
          let startCell = nextColumn;
          let modelColumnStartCell = nextColumn;
          for (let b = 0; b < header[a].modelHeader.length; b++) {
            const lastMergeCol = header[a].modelHeader[b].cellMerge + startCell;
            const startColName = this.getColumnByNumber(startCell + 1);
            const stopColName = this.getColumnByNumber(lastMergeCol);
            const colRange = `${startColName}7:${stopColName}7`;
            // console.log(`startCell: ${startCell} lastMergeCol: ${lastMergeCol} header will merge: ${colRange}`);
            ws.mergeCells(colRange);
            ws.getCell(`${stopColName}7`).value =
              header[a].modelHeader[b].headerName;
            this.rowFormat(ws.getRow(7), lastMergeCol, "center", "FFE699");
            startCell += header[a].modelHeader[b].cellMerge;
            // หัวคอลัมน์ที่เป็นชื่อโมเดลรถ
            for (let c = 0; c < header[a].modelHeader[b].models.length; c++) {
              modelColumnStartCell += 1;
              const startModelColName =
                this.getColumnByNumber(modelColumnStartCell);
              ws.getCell(`${startModelColName}8`).value =
                header[a].modelHeader[b].models[c].modelName;
              this.rowFormat(
                ws.getRow(8),
                modelColumnStartCell,
                "center",
                "FFE699"
              );
            }
          }
        }
      }
      // set row height
      ws.getRow(8).height = 23.0;
    }
    // console.log(data);
    // ----- add row header Media -----
    {
      data.forEach((d) => {
        // ws.addRow(emptyRow);
        const row = ["", d.dealerCode, d.dealerName];
        // for (const source of d.sources) {
        d.sources.forEach((source) => {
          let totalBySource: any = 0;
          source.modelHeader.forEach((mo) => {
            mo.models.forEach((v) => {
              const value: any = v.value;
              row.push(value);
              if (v.modelName === "Total") {
                totalBySource += v.value;
              }
            });
          });
          row.push(totalBySource);
        });
        // console.log('row: ', row);
        ws.addRow(row);
        // Get the last editable row in a worksheet (or undefined if there are none)
        // tslint:disable-next-line: no-shadowed-variable
        const cellLength = ws.getRow(ws.lastRow._number - 1)._cells.length;
        // console.log('number: ', ws.lastRow._number,' cell len: ', ws.getRow(ws.lastRow._number - 1)._cells.length);
        for (let a = 3; a <= cellLength; a++) {
          ws.lastRow.getCell(a).numFmt = "#,##0";
          ws.lastRow.getCell(a).alignment = {
            vertical: "middle",
            horizontal: a > 3 ? "center" : "left",
          };
          ws.lastRow.getCell(a).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
        }
      });
    }

    this.autoSizeColumn(ws, 6);
    // save xlsx file
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(
        blob,
        "Marketing-Report-Dealer-Follow-Up-Lead_" + tail + ".xlsx"
      );
    });
  }

  public exportDealerActivityToXlsx(
    data: DealerActivitiesList[],
    header: Sources[],
    fileName: string,
    dealerName: string
  ) {
    // console.log('data: ', data);
    const SALE_ACTIVITY = 0;
    const BOOKING_STATUS = 1;
    const DELIVERY = 2;
    const LOST = 3;
    const tail = `${fileName}_${moment().format("x")}`;
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const REPORT_NAME = "Dealer Activity";
    const ws = wb.addWorksheet(REPORT_NAME);
    const emptyRow = ["", "", ""];
    // console.log(header);
    // loop throuth header
    header.forEach((a) => {
      for (let b = 0; b < a.cellMerge; b++) {
        emptyRow.push("");
      }
    });
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DEALER";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = dealerName;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    const date: any[] = fileName.split("_");
    ws.getCell(`C${currentRow}`).value = `${moment(date[0]).format(
      "DD MMM YYYY"
    )} - ${moment(date[1]).format("DD MMM YYYY")}`;

    // add header
    ws.addRow(emptyRow);
    ws.addRow(emptyRow);
    const colrange = `B6:C7`;
    ws.mergeCells(colrange);
    ws.getCell(`C6`).value = "Assigned Dealer";
    this.rowFormat(ws.getRow(6), 3);
    ws.addRow(emptyRow);
    ws.getCell("B8").value = "Dealer Code";
    this.rowFormat(ws.getRow(8), 2);
    ws.getCell("C8").value = "Dealer Name";
    this.rowFormat(ws.getRow(8), 3);
    // add row 5th
    ws.addRow(emptyRow);
    this.rowFormat(ws.getRow(9), 2);
    this.rowFormat(ws.getRow(9), 3);
    // ----- add row header Dealer -----
    let startCell = 4;
    for (let indexA = 0; indexA < header.length; indexA++) {
      if (indexA === SALE_ACTIVITY) {
        // Sales Activity
        const colsMerge = header[indexA].cellMerge + 3;
        const theColumnName = this.getColumnByNumber(colsMerge);
        const cr = `D6:${theColumnName}6`;
        // console.log( cr);
        ws.mergeCells(cr);
        ws.getCell(`${theColumnName}6`).value = header[indexA].sourceName;
        this.rowFormat(ws.getRow(6), colsMerge, "center", "E2EFDA");

        const TOTAL_LEAD_COLUMN = "x";
        for (let indexB = 0; indexB < header[indexA].headers.length; indexB++) {
          if (header[indexA].headers[indexB].id === TOTAL_LEAD_COLUMN) {
            const startColumnName = "D7";
            const stopColumnName = "D9";
            const columnRange = `${startColumnName}:${stopColumnName}`;
            // console.log('...sale range: ', columnRange, ' index:', indexB);
            ws.mergeCells(columnRange);
            // Total Lead (Based on Filter "Prospect Source")
            ws.getCell(`${stopColumnName}`).value =
              header[indexA].headers[indexB].headerNameEn;
            this.rowFormat(ws.getRow(7), 4, "center", "E2EFDA", true);
            startCell += 1;
          } else {
            const startCol = this.getColumnByNumber(startCell);
            const colRange = `${startCol}7:${startCol}8`;
            ws.mergeCells(colRange);
            ws.getCell(`${startCol}7`).value =
              header[indexA].headers[indexB].headerNameEn;
            this.rowFormat(ws.getRow(7), startCell, "center", "E2EFDA");
            ws.getCell(`${startCol}9`).value =
              header[indexA].headers[indexB].headerNameTh;
            this.rowFormat(ws.getRow(9), startCell, "center", "E2EFDA");
            startCell += 1;
          }
        }
      } else if (indexA === BOOKING_STATUS) {
        // Booking Status
        const colsMerge = header[indexA].cellMerge + (startCell - 1);
        const theFirstColumnName = this.getColumnByNumber(startCell);
        const theLastColumnName = this.getColumnByNumber(colsMerge);
        const cr = `${theFirstColumnName}6:${theLastColumnName}6`;
        // console.log(cr);
        ws.mergeCells(cr);
        ws.getCell(`${theLastColumnName}6`).value = header[indexA].sourceName;
        this.rowFormat(ws.getRow(6), colsMerge, "center", "F8CBAD");
        for (let indexB = 0; indexB < header[indexA].headers.length; indexB++) {
          const currentColumn = this.getColumnByNumber(startCell);
          const colRange = `${currentColumn}7:${currentColumn}8`;
          // console.log('...booking range: ', colRange, ' index:', indexB);
          ws.mergeCells(colRange);
          ws.getCell(`${currentColumn}7`).value =
            header[indexA].headers[indexB].headerNameEn;
          this.rowFormat(ws.getRow(7), startCell, "center", "F8CBAD");
          ws.getCell(`${currentColumn}9`).value =
            header[indexA].headers[indexB].headerNameTh;
          this.rowFormat(ws.getRow(9), startCell, "center", "F8CBAD");
          startCell += 1;
        }
      } else if (indexA === DELIVERY) {
        // Delivery Status
        const colsMerge = header[indexA].cellMerge + (startCell - 1);
        const theFirstColumnName = this.getColumnByNumber(startCell);
        const theLastColumnName = this.getColumnByNumber(colsMerge);
        const cr = `${theFirstColumnName}6:${theLastColumnName}6`;
        // console.log(cr);
        ws.mergeCells(cr);
        ws.getCell(`${theLastColumnName}6`).value = header[indexA].sourceName;
        this.rowFormat(ws.getRow(6), colsMerge, "center", "DAE3F3");
        for (let indexB = 0; indexB < header[indexA].headers.length; indexB++) {
          const currentColumn = this.getColumnByNumber(startCell);
          const colRange = `${currentColumn}7:${currentColumn}8`;
          // console.log('...Delivery range: ', colRange);
          ws.mergeCells(colRange);
          ws.getCell(`${currentColumn}7`).value =
            header[indexA].headers[indexB].headerNameEn;
          this.rowFormat(ws.getRow(7), startCell, "center", "DAE3F3");
          if (indexB === 0) {
            const delieryMergeRange = `${currentColumn}9:${theLastColumnName}9`;
            ws.mergeCells(delieryMergeRange);
            ws.getCell(`${currentColumn}9`).value =
              header[indexA].headers[indexB].headerNameTh;
            this.rowFormat(ws.getRow(9), startCell, "center", "DAE3F3");
          }
          startCell += 1;
        }
      } else {
        // Lost Status
        const colsMerge = header[indexA].cellMerge + (startCell - 1);
        const theFirstColumnName = this.getColumnByNumber(startCell);
        const theLastColumnName = this.getColumnByNumber(colsMerge);
        const cr = `${theFirstColumnName}6:${theLastColumnName}6`;
        // console.log(cr);
        ws.mergeCells(cr);
        ws.getCell(`${theLastColumnName}6`).value = header[indexA].sourceName;
        this.rowFormat(ws.getRow(6), colsMerge, "center", "BFBFBF");
        for (let indexB = 0; indexB < header[indexA].headers.length; indexB++) {
          const currentColumn = this.getColumnByNumber(startCell);
          const colRange = `${currentColumn}7:${currentColumn}8`;
          ws.mergeCells(colRange);
          ws.getCell(`${currentColumn}7`).value =
            header[indexA].headers[indexB].headerNameEn;
          this.rowFormat(ws.getRow(7), startCell, "center", "BFBFBF");
          ws.getCell(`${currentColumn}9`).value =
            header[indexA].headers[indexB].headerNameTh;
          this.rowFormat(ws.getRow(9), startCell, "center", "BFBFBF");
          startCell += 1;
        }
      }
    }
    // set row height
    ws.getRow(7).height = 14.0;
    ws.getRow(8).height = 14.0;
    ws.getRow(9).height = 28.0;

    // console.log(data);
    // ----- add row header Media -----
    {
      data.forEach((d) => {
        // ws.addRow(emptyRow);
        const row = ["", d.dealerCode, d.dealerName];
        // for (const source of d.sources) {
        d.sources.forEach((source) => {
          source.headers.forEach((v) => {
            const value: any = v.values;
            row.push(value);
          });
        });

        // console.log('row: ', row);
        ws.addRow(row);
        const rows = ws.getRow(ws.lastRow._number);
        const lastRow = rows._number;
        // console.log(rows);

        rows.getCell(4).value = { formula: `SUM(E${lastRow}:Q${lastRow})` };
        for (let a = 4; a <= rows._cells.length; a++) {
          rows.getCell(a).numFmt = "#,##0";
          rows.getCell(a).alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          rows.getCell(a).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
          if (a === 18) {
            rows.getCell(a).value = {
              formula: `D${lastRow} - (S${lastRow} + T${lastRow} + U${lastRow})`,
            };
          }
        }
      });
    }

    this.autoSizeColumn(ws, 7);
    ws.getColumn("D").width = 14.6;
    // save xlsx file
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, "Marketing-Report-Dealer-Activity_" + tail + ".xlsx");
    });
  }

  async exportExtractProspectDataToXlsx(
    data: Extracts[],
    header: ExtractHeader,
    fileName: string,
    dealerName: string,
    source: string,
    status: string,
    model: string,
    usePredata: boolean
  ) {
    const tail = `${fileName}_${moment().format("x")}`;
    const wb = new Excel.Workbook();

    wb.creator = "Ford LMS";
    const REPORT_NAME = "Raw Data Report";
    const ws = wb.addWorksheet(REPORT_NAME);
    const emptyRow = [];
    // console.log('data: ', data);
    // console.log(dealerName);
    // loop throuth header
    for (let b = 0; b < 84; b++) {
      emptyRow.push(null);
    }
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DEALER";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = dealerName;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    const date: any[] = fileName.split("_");

    ws.getCell(`C${currentRow}`).value = `${moment(date[0]).format(
      "DD MMM YYYY"
    )} - ${moment(date[1]).format(
      "DD MMM YYYY"
    )} / Source: ${source} / Status: ${status} / Model: ${model} / Source (Day-1, Normal): ${usePredata === true ? "Day -1" : "Normal"
      }`;

    // add header
    ws.addRow(emptyRow);
    ws.addRow(emptyRow);
    ws.getCell(`B6`).value = header.dealerCode;
    ws.getCell(`C6`).value = header.dealerName;
    ws.getCell(`D6`).value = header.emp_queueNo; //emp
    ws.getCell(`E6`).value = header.emp_edited; //emp
    ws.getCell(`F6`).value = header.emp_editedDateTime; //emp
    ws.getCell(`G6`).value = header.bpid;
    ws.getCell(`H6`).value = header.leadSource;
    ws.getCell(`I6`).value = header.crmLeadId;
    ws.getCell(`J6`).value = header.leadId;
    ws.getCell(`K6`).value = header.leadCreatedDate;
    ws.getCell(`L6`).value = header.campaignId;
    ws.getCell(`M6`).value = header.campaignName;
    ws.getCell(`N6`).value = header.dateSentToDealer;
    ws.getCell(`O6`).value = header.contactDate;
    ws.getCell(`P6`).value = header.activityDate;
    ws.getCell(`Q6`).value = header.title;
    ws.getCell(`R6`).value = header.name;
    ws.getCell(`S6`).value = header.lastName;
    ws.getCell(`T6`).value = header.mobile;
    ws.getCell(`U6`).value = header.email;
    ws.getCell(`V6`).value = header.address;
    ws.getCell(`W6`).value = header.modelId;
    ws.getCell(`X6`).value = header.entityCode;
    ws.getCell(`Y6`).value = header.emp_mmyCode; //emp
    ws.getCell(`Z6`).value = header.modelDescription;
    ws.getCell(`AA6`).value = header.optionCode;
    ws.getCell(`AB6`).value = header.optionName; //emp
    ws.getCell(`AC6`).value = header.colorCode;
    ws.getCell(`AD6`).value = header.colorName;
    ws.getCell(`AE6`).value = header.trimCode;
    ws.getCell(`AF6`).value = header.trimName;
    ws.getCell(`AG6`).value = header.prospectStatus;
    ws.getCell(`AH6`).value = header.willBuyAt;
    ws.getCell(`AI6`).value = header.source;
    ws.getCell(`AJ6`).value = header.sourceSub;
    ws.getCell(`AK6`).value = header.media;
    ws.getCell(`AL6`).value = header.mediaSub;
    ws.getCell(`AM6`).value = header.reasonBuyCar;
    ws.getCell(`AN6`).value = header.callAppointment;
    ws.getCell(`AO6`).value = header.called;
    ws.getCell(`AP6`).value = header.callFailed;
    ws.getCell(`AQ6`).value = header.showroomAppointment;
    ws.getCell(`AR6`).value = header.showroomAppointmentDone;
    ws.getCell(`AS6`).value = header.showroomAppointmentCancel;
    ws.getCell(`AT6`).value = header.appointmentOutside;
    ws.getCell(`AU6`).value = header.appointmentOutsideDone;
    ws.getCell(`AV6`).value = header.appointmentOutsideCancel;
    ws.getCell(`AW6`).value = header.appointmentTestDrive;
    ws.getCell(`AX6`).value = header.appointmentTestDriveDone;
    ws.getCell(`AY6`).value = header.appointmentTestDriveCancel;
    ws.getCell(`AZ6`).value = header.brochureSent;
    ws.getCell(`BA6`).value = header.quotationCreated;
    ws.getCell(`BB6`).value = header.deliveryAppointment; // นัดส่งมอบรถ
    ws.getCell(`BC6`).value = header.preemtionCancel; // ยกเลิกจอง
    ws.getCell(`BD6`).value = header.lost;
    ws.getCell(`BE6`).value = header.cancelBy;
    ws.getCell(`BF6`).value = header.salesActivity;
    ws.getCell(`BG6`).value = header.bookingDateTime;
    ws.getCell(`BH6`).value = header.bookingNo;
    ws.getCell(`BI6`).value = header.emp_bookingNo; //เลข booking ที่ได้จาก emp
    ws.getCell(`BJ6`).value = header.bookingStatus;
    ws.getCell(`BK6`).value = header.reservationMoney;
    ws.getCell(`BL6`).value = header.vin;
    ws.getCell(`BM6`).value = header.deliveryDate;
    ws.getCell(`BN6`).value = header.saleStarId;
    ws.getCell(`BO6`).value = header.salesName;
    ws.getCell(`BP6`).value = header.saleMobile;
    ws.getCell(`BQ6`).value = header.financeActivity;
    ws.getCell(`BR6`).value = header.finance1;
    ws.getCell(`BS6`).value = header.status1;
    ws.getCell(`BT6`).value = header.finance2;
    ws.getCell(`BU6`).value = header.status2;
    ws.getCell(`BV6`).value = header.finance3;
    ws.getCell(`BW6`).value = header.status3;
    ws.getCell(`BX6`).value = header.note;
    ws.getCell(`BY6`).value = header.activeStatus;
    ws.getCell(`BZ6`).value = header.wersCode;
    ws.getCell(`CA6`).value = header.pandaCode;
    ws.getCell(`CB6`).value = header.description_th;
    ws.getCell(`CC6`).value = header.description_en;
    ws.getCell(`CD6`).value = header.latestStaff;
    ws.getCell(`CE6`).value = header.consent;
    ws.getCell(`CF6`).value = header.vinFromDms;
    ws.getCell(`CG6`).value = header.financeLastedStatus;
    ws.getCell(`CH6`).value = header.financeStatus01;
    ws.getCell(`CI6`).value = header.financeStatus02;
    ws.getCell(`CJ6`).value = header.financeStatus03;
    ws.getCell(`CK6`).value = header.financeStatus04;
    ws.getCell(`CL6`).value = header.financeStatus05;
    ws.getCell(`CM6`).value = header.marketingLocation;

    const ASSIGN_DEALER_NAME = 3;
    const EDITED_DATE_TIME = 6;
    const DATE_SALE_ACTIVITY = 13;
    const ADDRESS = 22;
    const WILLBUYAT = 34;
    const REASON_TO_BUY = 39;
    const CALL_BUSY = 42;
    const CANCEL_MEET_SHOWROOM = 45;
    const CANCEL_MEET_OTHERPLACE = 48;
    const CANCEL_TEST_DRIVE = 51;
    const SALE_ACTIVITY = 58;
    const DELIVERY_DATE = 65;
    const MOBILE_SALE_PERSON = 68;
    const STATUS = 75;
    const NOTES = 76;
    const WERS = 81;
    const CONSENT = 83;
    const VINFROMDMS = 91;

    for (let a = 0; a < VINFROMDMS; a++) {
      const currentIndex = a + 2;
      if (currentIndex <= ASSIGN_DEALER_NAME) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D9D9D9", true);
      } else if (currentIndex <= EDITED_DATE_TIME) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "CCC1DA", true);
      } else if (currentIndex <= DATE_SALE_ACTIVITY) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D9D9D9", true);
      } else if (currentIndex <= ADDRESS) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "FDEADA", true);
      } else if (currentIndex <= WILLBUYAT) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "CCC1DA", true);
      } else if (currentIndex <= REASON_TO_BUY) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D2DBE5", true);
      } else if (currentIndex <= CALL_BUSY) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "00B0F0", true);
      } else if (currentIndex <= CANCEL_MEET_SHOWROOM) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "FFFF00", true);
      } else if (currentIndex <= CANCEL_MEET_OTHERPLACE) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "FCD5B5", true);
      } else if (currentIndex <= CANCEL_TEST_DRIVE) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "C3D69B", true);
      } else if (currentIndex <= SALE_ACTIVITY) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D2DBE5", true);
      } else if (currentIndex <= DELIVERY_DATE) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "CCC1DA", true);
      } else if (currentIndex <= MOBILE_SALE_PERSON) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "4474A0", true);
      } else if (currentIndex <= STATUS) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "93CDDD", true);
      } else if (currentIndex <= NOTES) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D9D9D9", true);
      } else if (currentIndex <= WERS) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "C3D69B", true);
      } else if (currentIndex <= CONSENT) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D9D9D9", true);
      } else if (currentIndex <= VINFROMDMS) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D9D9D9", true);
      }
    }

    // set row height
    ws.getRow(6).height = 28.0;

    // console.log(data);
    // ----- add row header Media -----
    {
      data.forEach(async (d) => {
        // ws.addRow(emptyRow);
        const row = [""];
        row.push(d.dealerCode);
        row.push(d.dealerName);
        row.push(d.emp_queueNo);
        row.push(d.emp_edited);
        row.push(d.emp_editedDateTime);
        row.push(d.bpid);
        row.push(d.leadSource);
        row.push(d.crmLeadId);
        row.push(d.leadId);
        row.push(d.leadCreatedDate);
        row.push(d.campaignId);
        row.push(d.campaignName);
        row.push(d.dateSentToDealer);
        row.push(d.contactDate);
        row.push(d.activityDate);
        row.push(d.title);
        row.push(d.name);
        row.push(d.lastName);
        row.push(d.mobile);
        row.push(d.email);
        row.push(d.address);
        row.push(d.modelName);
        row.push(d.entityCode);
        row.push(d.emp_mmyCode);
        row.push(d.modelDescription);
        row.push(d.optionCode);
        row.push(d.optionName);
        row.push(d.colorCode);
        row.push(d.colorName);
        row.push(d.trimCode);
        row.push(d.trimName);
        row.push(d.prospectStatus);
        row.push(d.willBuyAt);
        row.push(d.source);
        row.push(d.sourceSub);
        row.push(d.media);
        row.push(d.mediaSub);
        row.push(d.reasonBuyCar);
        row.push(d.callAppointment > 0 ? d.callAppointment.toString() : "");
        row.push(d.called > 0 ? d.called.toString() : "");
        row.push(d.callFailed > 0 ? d.callFailed.toString() : "");
        row.push(
          d.showroomAppointment > 0 ? d.showroomAppointment.toString() : ""
        );
        row.push(
          d.showroomAppointmentDone > 0
            ? d.showroomAppointmentDone.toString()
            : ""
        );
        row.push(
          d.showroomAppointmentCancel > 0
            ? d.showroomAppointmentCancel.toString()
            : ""
        );
        row.push(
          d.appointmentOutside > 0 ? d.appointmentOutside.toString() : ""
        );
        row.push(
          d.appointmentOutsideDone > 0
            ? d.appointmentOutsideDone.toString()
            : ""
        );
        row.push(
          d.appointmentOutsideCancel > 0
            ? d.appointmentOutsideCancel.toString()
            : ""
        );
        row.push(
          d.appointmentTestDrive > 0 ? d.appointmentTestDrive.toString() : ""
        );
        row.push(
          d.appointmentTestDriveDone > 0
            ? d.appointmentTestDriveDone.toString()
            : ""
        );
        row.push(
          d.appointmentTestDriveCancel > 0
            ? d.appointmentTestDriveCancel.toString()
            : ""
        );
        row.push(d.brochureSent > 0 ? d.brochureSent.toString() : "");
        row.push(d.quotationCreated > 0 ? d.quotationCreated.toString() : "");
        // row.push(d.preemtion.toString());
        row.push(
          d.deliveryAppointment > 0 ? d.deliveryAppointment.toString() : ""
        );
        row.push(d.preemtionCancel > 0 ? d.preemtionCancel.toString() : "");
        // row.push(d.delivered.toString());
        row.push(d.lost > 0 ? d.lost.toString() : "");
        row.push(d.cancelBy);
        row.push(d.salesActivity);
        row.push(d.bookingDateTime);
        row.push(d.bookingNo);
        row.push(d.emp_bookingNo);
        row.push(d.bookingStatus);
        row.push(d.reservationMoney);
        row.push(d.vin);
        row.push(d.deliveryDate);
        row.push(d.saleStarId);
        row.push(d.salesName);
        row.push(d.saleMobile);
        row.push(d.financeActivity);
        row.push(d.finance1);
        row.push(d.status1);
        row.push(d.finance2);
        row.push(d.status2);
        row.push(d.finance3);
        row.push(d.status3);
        row.push(d.note);
        row.push(d.activeStatus);
        row.push(d.wersCode);
        row.push(d.pandaCode);
        row.push(d.description_th);
        row.push(d.description_en);
        row.push(d.latestStaff);
        row.push(d.consent);
        row.push(d.isVinFromDMS);
        row.push(d.financeLastedStatus);
        row.push(d.financeStatus01);
        row.push(d.financeStatus02);
        row.push(d.financeStatus03);
        row.push(d.financeStatus04);
        row.push(d.financeStatus05);
        row.push(d.marketingLocation);
        // console.log('row: ', row);
        ws.addRow(row);

        const rows = ws.getRow(ws.lastRow._number);
        const lastRow = rows._number;
        rows.commit();
        const centerColumn = [
          2, 4, 5, 8, 10, 11, 12, 14, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
          43, 44, 45, 46, 47, 48, 44, 50, 51, 52, 53, 54, 55, 56,
        ];
        for (let a = 2; a <= rows._cells.length; a++) {
          let horizontal = "left";
          if (centerColumn.includes(a)) {
            horizontal = "center";
          }
          rows.getCell(a).alignment = {
            vertical: "middle",
            horizontal: horizontal,
          };
          rows.getCell(a).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
        }

        // A way to cope with high memory usage.
        // Looks like rows written to stream are not transferred to file in time,
        // so memory consumption grows because rows have relations to other objects.
        // Memory of related objects is not released while row is not transferred to the file.
        if (lastRow % 10 === 0) {
          await this.sleep(1);
        }
      });
    }

    this.autoSizeColumn(ws, 7);
    ws.getRow(6).height = 40;
    ws.getColumn("B").width = 10;
    ws.getColumn("H").width = 10;
    ws.getColumn("I").width = 12;
    ws.getColumn("Z").width = 10;
    ws.getColumn("AA").width = 10;

    ws.getColumn("AD").width = 10;
    ws.getColumn("AF").width = 10;
    ws.getColumn("AG").width = 10;
    ws.getColumn("AK").width = 10;

    ws.getColumn("AM").width = 10;
    ws.getColumn("AN").width = 10;
    ws.getColumn("AO").width = 10;
    ws.getColumn("AP").width = 10;
    ws.getColumn("AQ").width = 10;
    ws.getColumn("AR").width = 10;
    ws.getColumn("AS").width = 10;
    ws.getColumn("AT").width = 10;
    ws.getColumn("AU").width = 10;
    ws.getColumn("AV").width = 10;
    ws.getColumn("AW").width = 10;
    ws.getColumn("AX").width = 10;
    ws.getColumn("AY").width = 10;
    ws.getColumn("AZ").width = 10;
    ws.getColumn("BA").width = 10;
    ws.getColumn("BB").width = 10;
    ws.getColumn("BC").width = 10;
    ws.getColumn("BD").width = 10;
    ws.getColumn("CF").width = 10;

    if (
      ws.getColumn("BP").width < 10 ||
      ws.getColumn("BP").width == undefined
    ) {
      ws.getColumn("BP").width = 10;
    }
    if (
      ws.getColumn("BQ").width < 10 ||
      ws.getColumn("BQ").width == undefined
    ) {
      ws.getColumn("BQ").width = 10;
    }
    if (
      ws.getColumn("BR").width < 10 ||
      ws.getColumn("BR").width == undefined
    ) {
      ws.getColumn("BR").width = 10;
    }
    if (
      ws.getColumn("BS").width < 10 ||
      ws.getColumn("BS").width == undefined
    ) {
      ws.getColumn("BS").width = 10;
    }
    if (
      ws.getColumn("BT").width < 10 ||
      ws.getColumn("BT").width == undefined
    ) {
      ws.getColumn("BT").width = 10;
    }
    if (
      ws.getColumn("BU").width < 10 ||
      ws.getColumn("BU").width == undefined
    ) {
      ws.getColumn("BU").width = 10;
    }
    if (
      ws.getColumn("BV").width < 10 ||
      ws.getColumn("BV").width == undefined
    ) {
      ws.getColumn("BV").width = 10;
    }
    if (
      ws.getColumn("BW").width < 10 ||
      ws.getColumn("BW").width == undefined
    ) {
      ws.getColumn("BW").width = 10;
    }

    // save xlsx file
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(
        blob,
        "Marketing-Report-Extract-Prospect-Data_" + tail + ".xlsx"
      );
    });
  }

  async sleep(timeMs: number): Promise<void> {
    return new Promise((ok) =>
      setTimeout(() => {
        ok();
      }, timeMs)
    );
  }

  public exportExtractProspectDataToXlsxForDealer(
    data: Extracts[],
    header: ExtractHeader,
    fileName: string,
    dealerName: string,
    source: string,
    status: string,
    model: string
  ) {
    const tail = `${fileName}_${moment().format("x")}`;
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const REPORT_NAME = "Raw Data Report";
    const ws = wb.addWorksheet(REPORT_NAME);
    const emptyRow = [];
    // console.log(header);
    // console.log(dealerName);
    // loop throuth header
    for (let b = 0; b < 78; b++) {
      emptyRow.push("");
    }
    // add date group row
    let currentRow = 1;
    ws.getCell(`B${currentRow}`).value = "REPORT NAME";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = REPORT_NAME;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DEALER";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    ws.getCell(`C${currentRow}`).value = dealerName;
    currentRow++;
    ws.getCell(`B${currentRow}`).value = "DATE RANGE";
    ws.getCell(`B${currentRow}`).font = { bold: true };
    const date: any[] = fileName.split("_");
    ws.getCell(`C${currentRow}`).value = `${moment(date[0]).format(
      "DD MMM YYYY"
    )} - ${moment(date[1]).format(
      "DD MMM YYYY"
    )} / Source: ${source} / Status: ${status} / Model: ${model}`;

    // add header
    ws.addRow(emptyRow);
    ws.addRow(emptyRow);
    ws.getCell(`B6`).value = header.dealerCode;
    ws.getCell(`C6`).value = header.dealerName;
    ws.getCell(`D6`).value = header.bpid;
    ws.getCell(`E6`).value = header.leadSource;
    ws.getCell(`F6`).value = header.crmLeadId;
    ws.getCell(`G6`).value = header.leadId;
    ws.getCell(`H6`).value = header.leadCreatedDate;
    ws.getCell(`I6`).value = header.campaignId;
    ws.getCell(`J6`).value = header.campaignName;
    ws.getCell(`K6`).value = header.dateSentToDealer;
    ws.getCell(`L6`).value = header.contactDate;
    ws.getCell(`M6`).value = header.activityDate;
    ws.getCell(`N6`).value = header.title;
    ws.getCell(`O6`).value = header.name;
    ws.getCell(`P6`).value = header.lastName;
    ws.getCell(`Q6`).value = header.mobile;
    ws.getCell(`R6`).value = header.email;
    ws.getCell(`S6`).value = header.address;
    ws.getCell(`T6`).value = header.modelId;
    ws.getCell(`U6`).value = header.entityCode;
    ws.getCell(`V6`).value = header.modelDescription;
    ws.getCell(`W6`).value = header.optionCode;
    ws.getCell(`X6`).value = header.optionName; //emp
    ws.getCell(`Y6`).value = header.colorCode;
    ws.getCell(`Z6`).value = header.colorName;
    ws.getCell(`AA6`).value = header.trimCode;
    ws.getCell(`AB6`).value = header.trimName;
    ws.getCell(`AC6`).value = header.prospectStatus;
    ws.getCell(`AD6`).value = header.willBuyAt;
    ws.getCell(`AE6`).value = header.source;
    ws.getCell(`AF6`).value = header.sourceSub;
    ws.getCell(`AG6`).value = header.media;
    ws.getCell(`AH6`).value = header.mediaSub;
    ws.getCell(`AI6`).value = header.reasonBuyCar;
    ws.getCell(`AJ6`).value = header.callAppointment;
    ws.getCell(`AK6`).value = header.called;
    ws.getCell(`AL6`).value = header.callFailed;
    ws.getCell(`AM6`).value = header.showroomAppointment;
    ws.getCell(`AN6`).value = header.showroomAppointmentDone;
    ws.getCell(`AO6`).value = header.showroomAppointmentCancel;
    ws.getCell(`AP6`).value = header.appointmentOutside;
    ws.getCell(`AQ6`).value = header.appointmentOutsideDone;
    ws.getCell(`AR6`).value = header.appointmentOutsideCancel;
    ws.getCell(`AS6`).value = header.appointmentTestDrive;
    ws.getCell(`AT6`).value = header.appointmentTestDriveDone;
    ws.getCell(`AU6`).value = header.appointmentTestDriveCancel;
    ws.getCell(`AV6`).value = header.brochureSent;
    ws.getCell(`AW6`).value = header.quotationCreated;
    ws.getCell(`AX6`).value = header.deliveryAppointment; // นัดส่งมอบรถ
    ws.getCell(`AY6`).value = header.preemtionCancel; // ยกเลิกจอง
    ws.getCell(`AZ6`).value = header.lost;
    ws.getCell(`BA6`).value = header.cancelBy;
    ws.getCell(`BB6`).value = header.salesActivity;
    ws.getCell(`BC6`).value = header.bookingDateTime;
    ws.getCell(`BD6`).value = header.bookingNo;
    ws.getCell(`BE6`).value = header.bookingStatus;
    ws.getCell(`BF6`).value = header.reservationMoney;
    ws.getCell(`BG6`).value = header.vin;
    ws.getCell(`BH6`).value = header.deliveryDate;
    ws.getCell(`BI6`).value = header.saleStarId;
    ws.getCell(`BJ6`).value = header.salesName;
    ws.getCell(`BK6`).value = header.saleMobile;
    ws.getCell(`BL6`).value = header.financeActivity;
    ws.getCell(`BM6`).value = header.finance1;
    ws.getCell(`BN6`).value = header.status1;
    ws.getCell(`BO6`).value = header.finance2;
    ws.getCell(`BP6`).value = header.status2;
    ws.getCell(`BQ6`).value = header.finance3;
    ws.getCell(`BR6`).value = header.status3;
    ws.getCell(`BS6`).value = header.emp_bookingNo;
    ws.getCell(`BT6`).value = header.note;
    ws.getCell(`BU6`).value = header.activeStatus;
    ws.getCell(`BV6`).value = header.wersCode;
    ws.getCell(`BW6`).value = header.pandaCode;
    ws.getCell(`BX6`).value = header.description_th;
    ws.getCell(`BY6`).value = header.description_en;
    ws.getCell(`BZ6`).value = header.latestStaff;
    ws.getCell(`CA6`).value = header.consent;
    ws.getCell(`CB6`).value = header.vinFromDms;

    for (let a = 0; a < 80; a++) {
      const currentIndex = a + 2;
      if (currentIndex <= 13) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D9D9D9", true);
      } else if (currentIndex <= 19) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "FDEADA", true);
      } else if (currentIndex <= 30) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "CCC1DA", true);
      } else if (currentIndex <= 35) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D2DBE5", true);
      } else if (currentIndex <= 38) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "00B0F0", true);
      } else if (currentIndex <= 41) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "FFFF00", true);
      } else if (currentIndex <= 44) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "FCD5B5", true);
      } else if (currentIndex <= 47) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "C3D69B", true);
      } else if (currentIndex <= 54) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D2DBE5", true);
      } else if (currentIndex <= 60) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "CCC1DA", true);
      } else if (currentIndex <= 63) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "4474A0", true);
      } else if (currentIndex <= 70) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "93CDDD", true);
      } else if (currentIndex <= 72) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D9D9D9", true);
      } else if (currentIndex <= 77) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "C3D69B", true);
      } else if (currentIndex <= 79) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D9D9D9", true);
      } else if (currentIndex <= 80) {
        this.rowFormat(ws.getRow(6), currentIndex, "center", "D9D9D9", true);
      }
    }

    // set row height
    ws.getRow(6).height = 28.0;

    // console.log(data);
    // ----- add row header Media -----
    {
      data.forEach(async (d) => {
        // ws.addRow(emptyRow);
        const row = [""];
        row.push(d.dealerCode);
        row.push(d.dealerName);
        row.push(d.bpid);
        row.push(d.leadSource);
        row.push(d.crmLeadId);
        row.push(d.leadId);
        row.push(d.leadCreatedDate);
        row.push(d.campaignId);
        row.push(d.campaignName);
        row.push(d.dateSentToDealer);
        row.push(d.contactDate);
        row.push(d.activityDate);
        row.push(d.title);
        row.push(d.name);
        row.push(d.lastName);
        row.push(d.mobile);
        row.push(d.email);
        row.push(d.address);
        // row.push(d.contacted ? 'Contacted' : 'Not Contact Yet');
        row.push(d.modelName);
        row.push(d.entityCode);
        row.push(d.modelDescription);
        row.push(d.optionCode);
        row.push(d.optionName);
        row.push(d.colorCode);
        row.push(d.colorName);
        row.push(d.trimCode);
        row.push(d.trimName);
        row.push(d.prospectStatus);
        row.push(d.willBuyAt);
        row.push(d.source);
        row.push(d.sourceSub);
        row.push(d.media);
        row.push(d.mediaSub);
        row.push(d.reasonBuyCar);
        row.push(d.callAppointment > 0 ? d.callAppointment.toString() : "");
        row.push(d.called > 0 ? d.called.toString() : "");
        row.push(d.callFailed > 0 ? d.callFailed.toString() : "");
        row.push(
          d.showroomAppointment > 0 ? d.showroomAppointment.toString() : ""
        );
        row.push(
          d.showroomAppointmentDone > 0
            ? d.showroomAppointmentDone.toString()
            : ""
        );
        row.push(
          d.showroomAppointmentCancel > 0
            ? d.showroomAppointmentCancel.toString()
            : ""
        );
        row.push(
          d.appointmentOutside > 0 ? d.appointmentOutside.toString() : ""
        );
        row.push(
          d.appointmentOutsideDone > 0
            ? d.appointmentOutsideDone.toString()
            : ""
        );
        row.push(
          d.appointmentOutsideCancel > 0
            ? d.appointmentOutsideCancel.toString()
            : ""
        );
        row.push(
          d.appointmentTestDrive > 0 ? d.appointmentTestDrive.toString() : ""
        );
        row.push(
          d.appointmentTestDriveDone > 0
            ? d.appointmentTestDriveDone.toString()
            : ""
        );
        row.push(
          d.appointmentTestDriveCancel > 0
            ? d.appointmentTestDriveCancel.toString()
            : ""
        );
        row.push(d.brochureSent > 0 ? d.brochureSent.toString() : "");
        row.push(d.quotationCreated > 0 ? d.quotationCreated.toString() : "");
        // row.push(d.preemtion.toString());
        row.push(
          d.deliveryAppointment > 0 ? d.deliveryAppointment.toString() : ""
        );
        row.push(d.preemtionCancel > 0 ? d.preemtionCancel.toString() : "");
        // row.push(d.delivered.toString());
        row.push(d.lost > 0 ? d.lost.toString() : "");
        row.push(d.cancelBy);
        row.push(d.salesActivity);
        row.push(d.bookingDateTime);
        row.push(d.bookingNo);
        row.push(d.bookingStatus);
        row.push(d.reservationMoney);
        row.push(d.vin);
        row.push(d.deliveryDate);
        row.push(d.saleStarId);
        row.push(d.salesName);
        row.push(d.saleMobile);
        row.push(d.financeActivity);
        row.push(d.finance1);
        row.push(d.status1);
        row.push(d.finance2);
        row.push(d.status2);
        row.push(d.finance3);
        row.push(d.status3);
        row.push(d.emp_bookingNo);
        row.push(d.note);
        row.push(d.activeStatus);
        row.push(d.wersCode);
        row.push(d.pandaCode);
        row.push(d.description_th);
        row.push(d.description_en);
        row.push(d.latestStaff);
        row.push(d.consent);
        row.push(d.isVinFromDMS);
        row.push(d.financeLastedStatus);
        row.push(d.financeStatus01);
        row.push(d.financeStatus02);
        row.push(d.financeStatus03);
        row.push(d.financeStatus04);
        row.push(d.financeStatus05);
        row.push(d.marketingLocation);
        // console.log('row: ', row);
        ws.addRow(row);
        const rows = ws.getRow(ws.lastRow._number);
        const lastRow = rows._number;
        rows.commit();
        const centerColumn = [
          2, 5, 6, 7, 10, 11, 12, 13, 24, 28, 31, 32, 33, 34, 35, 36, 37, 38,
          39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 44, 50, 51,
        ];
        for (let a = 2; a <= rows._cells.length; a++) {
          let horizontal = "left";
          if (centerColumn.includes(a)) {
            horizontal = "center";
          }
          rows.getCell(a).alignment = {
            vertical: "middle",
            horizontal: horizontal,
          };
          rows.getCell(a).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            color: { argb: "000000" },
          };
        }
        // A way to cope with high memory usage.
        // Looks like rows written to stream are not transferred to file in time,
        // so memory consumption grows because rows have relations to other objects.
        // Memory of related objects is not released while row is not transferred to the file.
        if (lastRow % 10 === 0) {
          await this.sleep(0);
        }
      });
    }

    this.autoSizeColumn(ws, 7);
    ws.getRow(6).height = 40;
    ws.getColumn("B").width = 10;
    ws.getColumn("E").width = 10;
    ws.getColumn("F").width = 12;
    ws.getColumn("V").width = 10;
    ws.getColumn("W").width = 10;

    ws.getColumn("Y").width = 10;
    ws.getColumn("AA").width = 10;
    ws.getColumn("AB").width = 10;
    ws.getColumn("AF").width = 10;

    ws.getColumn("AH").width = 10;
    ws.getColumn("AI").width = 10;
    ws.getColumn("AJ").width = 10;
    ws.getColumn("AK").width = 10;
    ws.getColumn("AL").width = 10;
    ws.getColumn("AM").width = 10;
    ws.getColumn("AN").width = 10;
    ws.getColumn("AO").width = 10;
    ws.getColumn("AP").width = 10;
    ws.getColumn("AQ").width = 10;
    ws.getColumn("AR").width = 10;
    ws.getColumn("AS").width = 10;
    ws.getColumn("AT").width = 10;
    ws.getColumn("AU").width = 10;
    ws.getColumn("AV").width = 10;
    ws.getColumn("AW").width = 10;
    ws.getColumn("AX").width = 10;
    ws.getColumn("AY").width = 10;
    ws.getColumn("CB").width = 10; //vinfromdms
    if (
      ws.getColumn("BJ").width < 10 ||
      ws.getColumn("BJ").width == undefined
    ) {
      ws.getColumn("BJ").width = 10;
    }
    if (
      ws.getColumn("BK").width < 10 ||
      ws.getColumn("BK").width == undefined
    ) {
      ws.getColumn("BK").width = 10;
    }
    if (
      ws.getColumn("BL").width < 10 ||
      ws.getColumn("BL").width == undefined
    ) {
      ws.getColumn("BL").width = 10;
    }
    if (
      ws.getColumn("BM").width < 10 ||
      ws.getColumn("BM").width == undefined
    ) {
      ws.getColumn("BM").width = 10;
    }
    if (
      ws.getColumn("BN").width < 10 ||
      ws.getColumn("BN").width == undefined
    ) {
      ws.getColumn("BN").width = 10;
    }
    if (
      ws.getColumn("BO").width < 10 ||
      ws.getColumn("BO").width == undefined
    ) {
      ws.getColumn("BO").width = 10;
    }
    if (
      ws.getColumn("BP").width < 10 ||
      ws.getColumn("BP").width == undefined
    ) {
      ws.getColumn("BP").width = 10;
    }
    if (
      ws.getColumn("BR").width < 10 ||
      ws.getColumn("BP").width == undefined
    ) {
      ws.getColumn("BR").width = 10;
    }

    // save xlsx file
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(
        blob,
        "Marketing-Report-Extract-Prospect-Data_" + tail + ".xlsx"
      );
    });
  }

  private rowFormat(
    row: any,
    column: number,
    horizontal: string = "center",
    backgroundColor: string = "AAAAAA",
    isWrapText: boolean = false
  ) {
    row.getCell(column).alignment = {
      vertical: "middle",
      horizontal: horizontal,
      wrapText: isWrapText,
    };
    row.getCell(column).font = { bold: true, color: { argb: "000000" } };
    row.getCell(column).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: backgroundColor },
    };
    row.getCell(column).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      color: { argb: "000000" },
    };
  }

  private autoSizeColumn(ws: any, rowFrom: any) {
    const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const maxColumnLengths: Array<number> = [];
    ws.eachRow((row, rowNum) => {
      if (rowNum < rowFrom) {
        return;
      }

      row.eachCell((cell, num) => {
        if (typeof cell.value === "string") {
          if (maxColumnLengths[num] === undefined) {
            maxColumnLengths[num] = 0;
          }

          const fontSize = cell.font && cell.font.size ? cell.font.size : 11;
          ctx.font = `${fontSize}pt Arial`;
          const metrics = ctx.measureText(cell.value);
          const cellWidth = metrics.width;

          maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth);
        }
      });
    });

    for (let i = 1; i <= ws.columnCount; i++) {
      const col = ws.getColumn(i);
      const width = maxColumnLengths[i];
      if (width) {
        col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 1;
        // console.log('width: ', col.width);
      }
    }
  }

  public exportProspect2(
    series: any,
    dealers: any,
    prospects: any,
    customerSources: any
  ): void {
    // console.log('in function ', series);
    const allZones = Object.keys(series);
    const wb = new Excel.Workbook();
    wb.creator = "Ford LMS";
    const ws = wb.addWorksheet("Prospect2");

    // tslint:disable-next-line: max-line-length
    const HeadReportProspect2 = [
      "",
      "",
      "",
      "ลูกค้าเข้าโชว์รูม/กิจกรรมที่โชว์รูม",
      "ออกบูธ/กิจกรรมนอกโชว์รูม",
      "อินเตอร์เน็ต/เว็บไซต์",
      "ลูกค้าเก่า/ลูกค้าแนะนำ",
      "อื่นๆ (เช่น โทรเข้าโชว์รูม)",
      "Grand Total",
    ];

    ws.addRow([]);
    ws.addRow([]);
    ws.addRow(HeadReportProspect2).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "92D050" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    const setbg1 = ws.addRow([
      "ยอดจองคงค้างยกมาทั้งหมดไม่รวมเดือนปัจจุบัน (Pending Booking Excluding Current Month)",
    ]);

    // Row 4
    const headRow4 = ["", "", "", "Series"];
    const nameXL = [allZones[0]];
    const blank = new Array(series[allZones[0]].length).fill("");
    const name2 = [allZones[1]];
    const blank2 = new Array(series[allZones[1]].length).fill("");
    const Line4Data = headRow4.concat(nameXL, blank, name2, blank2);
    ws.addRow(Line4Data);

    // End Row 4

    const eachSerialName = [];

    series[allZones[0]].forEach((xl) => {
      eachSerialName.push(xl.carName);
    });
    const HeadTable = ["", "Zone", "Dlr Code", "Dlr Name"];
    const endSr1 = ["Total " + allZones[0]];
    const Line5Data = HeadTable.concat(eachSerialName, endSr1);

    ws.addRow(Line5Data);

    // Generate Excel File with given name
    wb.xlsx.writeBuffer().then((dataCar: ArrayBuffer) => {
      const blob = new Blob([dataCar], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.ws",
      });
      fs.saveAs(blob, "SaleReportProspect2.xlsx");
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    const DateName =
      new Date().getFullYear() +
      "" +
      new Date().getMonth().toFixed().padStart(2, "0") +
      "" +
      new Date().getDate().toFixed().padStart(2, "0") +
      "" +
      new Date().getHours().toFixed().padStart(2, "0") +
      "" +
      new Date().getMinutes().toFixed().padStart(2, "0") +
      "" +
      new Date().getSeconds().toFixed().padStart(2, "0");
    fs.saveAs(data, fileName + "_export_" + DateName + EXCEL_EXTENSION);
  }
}
