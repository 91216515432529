// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'

// Global
import { GlobalHandlerService } from './global-handler.service';
import { Router } from '@angular/router';

@Injectable()
export class RequestAPIService {

  private adminStorageKey = "admin";
  private dealerCodeStorageKey = "dealer";

  // Injecting the http client into the service
  constructor(
    private http: HttpClient,
    private globalHandler: GlobalHandlerService,
    private router: Router,
  ) { }

  // Post
  post(url: string, token: string = '', body: Object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': token,
        'Authorization': 'Bearer' + ' ' + token
      })
    };
    return this.http
      .post(url, body, httpOptions)
      .map(this.parseData)
      .catch(this.globalHandler.handleHttp);
  }

  // Post
  postFile(url: string, token: string = '', body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'token': token,
        'Authorization': 'Bearer' + ' ' + token
      })
    };
    return this.http
      .post(url, body, httpOptions)
      .map(this.parseData)
      .catch(this.globalHandler.handleHttp);
  }

  // Ping server for authentication
  get(url: string, token: string = ''): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': token,
        'Authorization': 'Bearer' + ' ' + token
      })
    };
    return this.http
      .get(url, httpOptions)
      .map(this.parseData)
      .catch(this.globalHandler.handleHttp);
  }

  getExcelSheet(url: string, token: string = ''): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'token': token,
      'Authorization': 'Bearer' + ' ' + token
    })
    return this.http
      .get(url, { headers, responseType: 'blob' as 'json' });
  }

  // Put
  put(url: string, token: string = '', body: Object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': token,
        'Authorization': 'Bearer' + ' ' + token
      })
    };
    return this.http
      .put(url, body, httpOptions)
      .map(this.parseData)
      .catch(this.globalHandler.handleHttp);
  }

  // Put
  putFile(url: string, token: string = '', body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'token': token,
        'Authorization': 'Bearer' + ' ' + token
      })
    };
    return this.http
      .put(url, body, httpOptions)
      .map(this.parseData)
      .catch(this.globalHandler.handleHttp);
  }

  // Delete
  delete(url: string, token: string = '', data: object = {}): Observable<any> {
    let httpOptions = {};

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': token,
        'Authorization': 'Bearer' + ' ' + token
      })
    };

    if (data !== {}) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token,
          'Authorization': 'Bearer' + ' ' + token
        }),
        body: data
      };
    }

    return this.http
      .delete(url, httpOptions)
      .map(this.parseData)
      .catch(this.globalHandler.handleHttp);
  }

  // The method checks whether there is a user logged in.
  isLoggedIn() {
    return localStorage.getItem(this.adminStorageKey) != null;
  }

  // The method retrieve the admin info.
  getLoggedInAdmin() {
    let admin: any;
    if (this.isLoggedIn()) {
      admin = JSON.parse(localStorage.getItem(this.adminStorageKey));
    }
    return admin;
  }

  getNameAdmin() {
    let firstName = '';
    if (this.isLoggedIn()) {
      const admin = JSON.parse(localStorage.getItem(this.adminStorageKey));
      firstName = admin.firstName;
    }
    return firstName;
  }

  getRoleCodeAdmin() {
    let roleCode = '';
    if (this.isLoggedIn()) {
      const admin = JSON.parse(localStorage.getItem(this.adminStorageKey));
      roleCode = admin.roleCode;
    }
    return roleCode;
  }

  getRoleNameAdmin() {
    let roleName = '';
    if (this.isLoggedIn()) {
      const admin = JSON.parse(localStorage.getItem(this.adminStorageKey));
      roleName = admin.roleName;
    }
    return roleName;
  }

  getTokenAdmin() {
    let token = '';
    if (this.isLoggedIn()) {
      const admin = JSON.parse(localStorage.getItem(this.adminStorageKey));
      token = admin.accessToken;
    }
    return token;
  }

  getUserId() {
    let userId = '';
    if (this.isLoggedIn()) {
      const admin = JSON.parse(localStorage.getItem(this.adminStorageKey));
      userId = admin.userId;
    }
    return userId;
  }

  getProfile() {
    let loginName = '';
    let firstName = '';
    let lastName = '';
    let email = '';
    let role = '';
    if (this.isLoggedIn()) {
      const admin = JSON.parse(localStorage.getItem(this.adminStorageKey));
      loginName = admin.loginName;
      firstName = admin.firstName;
      lastName = admin.lastName;
      email = admin.email;
      role = admin.roleName;
    }
    return { loginName, firstName, lastName, email, role };
  }

  // The method sets the Admin key object
  login(admin) {
    localStorage.setItem(this.adminStorageKey, JSON.stringify(admin));
  }

  setDealerCodeInLocal(data) {
    localStorage.setItem(this.dealerCodeStorageKey, data);
  }

  getDealerCodeInLocal() {
    const data = localStorage.getItem(this.dealerCodeStorageKey);
    return data;
  }

  // The method logouts the current user.
  logout() {
    localStorage.removeItem(this.adminStorageKey);
  }

  getCarSeiresDetails() {
    return this.http.get<any>('../../assets/config/serieOrdering.json');
  }
  getMediaOrdering() {
    return this.http.get<any>('../../assets/config/mediaOrdering.json');
  }

  // This method parses the data to JSON
  private parseData(res: HttpResponse<any>) {
    const data = res;
    if (data['error'] != null) {
      return data['error']
    } else {
      return data;
    }
  }

  public backToHomePage() {
    this.logout();
    this.router.navigate(['/']);
  }

}
