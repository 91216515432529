// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseAsset: '../assets/',
  // reportUrl: 'https://apifordlms.leadinnovation.co.th'
  // *prod server*
  //reportUrl: 'https://fordlms.leadinnovation.co.th/webapi'
  // * staging server *
  // reportUrl: 'https://apifordlms.leadinnovation.co.th/webdevapi',
  // * staging local *
  reportUrl: 'https://apifordlms.leadinnovation.co.th/webapi',
  // * staging link to prod *
  // reportUrl: 'https://fordlms.leadinnovation.co.th/juldev'
  // * staging link to prod *
  // reportUrl: 'https://fordlms.leadinnovation.co.th/apiwebdev'
  //api reset password
  // rePassUrl:'https://apifordlms.leadinnovation.co.th/webdevapi'
};
