import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportsService } from '../../reports/reports.service';
import { ApiRequest, ApiRequestV2 } from '../../reports/interface';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-head-search',
  templateUrl: './head-search.component.html',
  styleUrls: ['./head-search.component.css']
})
export class HeadSearchComponent implements OnInit {
  sZone = 'All';
  resSearchOutput = {
    label: [],
    data: [],
    total: 0
  };
  Zones = [
    { value: 'All', viewValue: 'All' },
    { value: 'Tier', viewValue: 'Tier' },
    { value: 'Zone Manager', viewValue: 'Zone Manager' }
  ];
  typeCar = [
    { value: 'all', viewValue: 'all' },
    { value: 'ranger', viewValue: 'ranger' },
    { value: 'everset', viewValue: 'everset' },
    { value: 'ecosport', viewValue: 'ecosport' }
  ];

  selDealerDetail = new FormControl(['All']);
  @Input() searchType: string;
  @Output() searchResult = new EventEmitter<any>();
  @Output() exportClick = new EventEmitter<any>();
  DealerDetail = [];
  Region = [];
  Tier = [];
  ZM = [];
  selTier = [];
  selZM = [];
  DealerCode = ['all'];
  cusSelDealerCode = '';
  selCarModel = '';
  masterOfDetail = '';
  constructor(private service: ReportsService) { }

  ngOnInit() {
    console.log('search type : ', this.searchType);
    this.initDealerDetail();

    this.selDealerDetail.valueChanges.subscribe(changeDetail => {
      this.selTier = [];
      this.selZM = [];

      const QueryDealer = {
        ZM: '',
        region: '',
        tier: ''
      };

      console.log('This change : ', changeDetail);

      switch (this.masterOfDetail) {
        case 'Tier': {
          this.selTier = changeDetail;
          QueryDealer.tier = changeDetail;
          break;
        }
        case 'Zone Manager': {
          this.selZM = QueryDealer.ZM = changeDetail;
          break;
        }
      }

      if (changeDetail.length) {
        this.service.getDealerCode(QueryDealer).subscribe(resDealer => {
          console.log('res ddd : ', resDealer);
          this.DealerCode = ['all'];

          resDealer.forEach(onceDealer => {
            this.DealerCode.push(onceDealer.dealerCode);
          });
        });
      }

    });
  }

  selChangeCar(changecar) {
    this.selCarModel = changecar;
  }

  initDealerDetail() {
    this.service.getDealerDetail().subscribe((resData: any) => {
      for (const res of resData) {
        this.DealerCode.push(res.DealerCode);
        this.Region.push(res.Region);
        this.Tier.push(res.Tier);
        this.ZM.push(res.ZM);

        const setRegion = new Set(this.Region);
        const setTier = new Set(this.Tier);
        const setZM = new Set(this.ZM);

        this.Region = [...setRegion];
        this.Tier = [...setTier];
        this.ZM = [...setZM];


      }
    });
  }

  clickSearch() {
    console.log('clickckkkkckckc');

    const sendReqSaleReport: ApiRequestV2 = {
      dealerCode: this.cusSelDealerCode,
      endDate: '',
      startDate: '',
      typeCar: this.selCarModel,
      ZM: this.selZM,
      tier: this.selTier
    };

    this.service.getSaleReport(sendReqSaleReport).subscribe((resSaleReport) => {
      console.log(resSaleReport);
      this.resSearchOutput.label = [];
      this.resSearchOutput.data = [];
      this.resSearchOutput.total = 0;

      if (resSaleReport.code === 0) {
        resSaleReport.data.forEach(report => {
          this.resSearchOutput.label.push(report.region);
          this.resSearchOutput.data.push(report.TotalRegion);
          this.resSearchOutput.total += report.TotalRegion;
        });
      }

      this.searchResult.emit(this.resSearchOutput);
    });
  }
  selDealerCode(inDealerCode) {
    console.log('Sel dealer code : ', inDealerCode);
    this.cusSelDealerCode = inDealerCode;
  }
  selectZone(inSearch) {
    console.log('after select : ', inSearch);
    this.masterOfDetail = inSearch;

    this.DealerDetail = [];
    switch (inSearch) {
      case 'Region': {
        return this.DealerDetail = this.Region;
        break;
      }
      case 'Tier': {
        return this.DealerDetail = this.Tier;
        break;
      }
      case 'Zone Manager': {
        return this.DealerDetail = this.ZM;
        break;
      }
    }

  }
  clickExport() {
    this.exportClick.emit();
  }
  clickSubmit() {
    console.log('click submit');
  }
}
