import { NgModule } from '@angular/core';

import { MenuItems, MenuAdminItems } from './menu-items/menu-items';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { HeadSearchComponent } from './head-search/head-search.component';
import { DemoMaterialModule } from '../demo-material-module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    HeadSearchComponent,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    HeadSearchComponent
  ],
  imports: [
    DemoMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
  ],
  providers: [MenuItems, MenuAdminItems],
  entryComponents: []
})
export class SharedModule { }
