// Angular
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http'
import { Observable } from 'rxjs/Rx'

// Global
import { ToasterService } from 'angular2-toaster';

@Injectable()
export class GlobalHandlerService implements ErrorHandler {

  constructor(
    private toasterService: ToasterService,
  ) {
    this.toasterService = toasterService;
  }

  handleError(error: any, title?: string) {
    const message = error.message ? error.message : error.toString();
    this.toasterService.pop('error', title ? title : 'Error', message);
  }

  handleSuccess(msg: any, title?: string) {
    this.toasterService.pop('success', title ? title : 'Success', msg);
  }

  handleWarning(msg: any, title?: string) {
    this.toasterService.pop('warning', title ? title : 'Warning', msg);
  }

  // Error catcher for HTTP request services
  handleHttp(error: HttpErrorResponse) {
    let message: string;
    if (error instanceof Object) {
      message = error["error"].error;
    }
    if (error.error instanceof ErrorEvent) {
      return Observable.throw(error.error)
    } else {
      if (message == undefined || message == null) {
        message = 'Unknown Error Occurred'
      }
      return Observable.throw(message)
    }
  }

}
