import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { OverallModel } from './interface';

// Services
import { RequestAPIService } from '../services/request-api.service'
import { GlobalHandlerService } from '../services/global-handler.service'
@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  baseUrl = environment.baseAsset;
  reportUrl = environment.reportUrl;

  constructor(
    private http: HttpClient,
    private requestAPIService: RequestAPIService,
    private globalHandlerService: GlobalHandlerService
  ) { }

  /**
   * SET CONFIG HEADERS
   */
  config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  /**
   *  CALL METHOD GET
   */
  get(module: string, path: string) {
    const config = this.config;
    const url = this.reportUrl;
    return this.http.get(url, config);
  }

  /**
   * CALL METHOD POST
   */
  post(body: Object = {}, path: string) {
    const config = this.config;
    const url = this.reportUrl + path;
    return this.http.post<any>(url, body, config);
  }

  getPospectData() {
    return this.http.get<any>(this.baseUrl + '/config/pospect_data.json');
  }

  getDealerDetail() {
    return this.http.get<any>(this.baseUrl + '/config/dealerDetail.json');
  }

  getUserLog() {
    return this.http.get<any>(this.baseUrl + '/config/userLog.json');
  }

  getMockUserLog() {
    return this.http.get<any>(this.baseUrl + '/config/userLog.json');
  }

  getMonth() {
    return this.http.get<any>(this.baseUrl + '/config/month.json');
  }
  getYear() {
    return this.http.get<any>(this.baseUrl + '/config/year.json');
  }
  getDataApi(data) {
    return this.post(data, 'getDataApi');
  }
  getSaleReport(data) {
    return this.post(data, 'getSaleReport');
  }
  getprospect(data) {
    return this.post(data, 'getprospect');
  }
  getBooking(data) {
    return this.post(data, 'getBooking');
  }
  getDelivery(data) {
    return this.post(data, 'getDelivery');
  }

  getOverAllReport() {
    return this.http.get<OverallModel[]>(this.baseUrl + '/config/overview-report-response.json');
  }

  getDealerCode(dealerBody) {
    return this.post(dealerBody, 'getDealerCode');
  }

  getBookingData() {
    return this.http.get<any>(this.baseUrl + '/config/bookingData.json');
  }

  getDeliveryData() {
    return this.http.get<any>(this.baseUrl + '/config/deliveryData.json');
  }

  getTestDriveData() {
    return this.http.get<any>(this.baseUrl + '/config/testDriveRatioData.json');
  }

  getClosingData() {
    return this.http.get<any>(this.baseUrl + '/config/closingRatioData.json');
  }

  getConvertionData() {
    return this.http.get<any>(this.baseUrl + '/config/convertionRatioData.json');
  }

  getSCproductData() {
    return this.http.get<any>(this.baseUrl + '/config/scProductivityData.json');
  }

  getpospect_Data1() {
    return this.http.get<any>(this.baseUrl + '/config/pospect_Data1.json');
  }

}
